import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { getWithdrawalList } from '../../services/repositories/WithdrawalListService';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff',
  minHeight: '100vh',
  paddingTop: theme.spacing(4),
}));

const StyledTable = styled(Table)(({ theme }) => ({
  fontFamily: 'Hind Madurai, sans-serif',
  '& th': {
    fontWeight: 'bold',
    fontFamily: 'Hind Madurai, sans-serif',
    color: '#581a7d',
  },
  '& td': {
    // fontFamily: 'Hind Madurai, sans-serif',
    verticalAlign: 'middle',
    height: '60px',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    color: '#581a7d',
    fontFamily: 'Hind Madurai, sans-serif',
    padding: 0,
    border: 'none',
    background: 'none',
    textDecoration: 'underline',
    '&:hover': {
      background: 'none',
      textDecoration: 'underline',
    },
}));

const TableWrapper = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(4),
}));

const statusMap = {
  0: { text: 'Pending', color: 'orange' },
  1: { text: 'Approved', color: 'green' },
  2: { text: 'Rejected', color: 'red' },
};

function WithdrawalList({ isStatement = false }) {
  const [withdrawals, setWithdrawals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchWithdrawalList = async () => {
      try {
        const data = await getWithdrawalList(navigate);
        setWithdrawals(data.data);
      } catch (error) {
        console.error('Error fetching withdrawal list:', error);
        setError('Failed to fetch withdrawal list.');
      } finally {
        setLoading(false);
      }
    };

    fetchWithdrawalList();
  }, [navigate]);

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          width: '100%',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <StyledBox>
      <Container sx={{ mt: isStatement?0:6 }}>
        {!isStatement && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2 }}>
            <StyledButton onClick={() => navigate(-1)}>Back</StyledButton>
          </Box>
        )}

        {error ? (
          <Typography color="error" sx={{ fontFamily: 'Hind Madurai, sans-serif' }}>{error}</Typography>
        ) : (
          <TableWrapper>
            <TableContainer component={Paper}>
              <StyledTable>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ textAlign: 'left' }}>Amount</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {withdrawals.map((withdrawal) => (
                    <TableRow key={withdrawal.id}>
                      <TableCell>₹{withdrawal.amount}</TableCell>
                      <TableCell 
                        sx={{ 
                          color: statusMap[withdrawal.status].color, 
                          textAlign: 'center',
                          fontWeight: 'bold' 
                        }}
                      >
                        {statusMap[withdrawal.status].text}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </StyledTable>
            </TableContainer>
          </TableWrapper>
        )}
      </Container>
    </StyledBox>
  );
}

export default WithdrawalList;
