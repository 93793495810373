export const handleLogout = (responseCode, navigate) => {
  if (responseCode === 1016 || responseCode === '1016') {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('email');

    navigate('/', { replace: true });
    window.location.reload();

    return true;  
  }
  return false;  
};
