import axios from 'axios';
import { API_ENDPOINTS } from '../../config/ApiConfig';
import LoginModel from '../models/LoginModel';

export const postLogin = async ({ email, password, rememberMe }) => {
  try {
    const response = await axios.post(API_ENDPOINTS.postLogin, {
      email,
      password,
      remember_me: rememberMe,
    });

    const data = response.data;

    // if (response.status === 200) {
    //   const loginData = new LoginModel(data);
    //   return loginData;
    // } else if (response.status === 404) {
    //   console.error(`API Error: (${response.status}) ${data.code} - ${data.message}`);
    //   const loginData = new LoginModel(data);
    //   return loginData;
    // } else {
    //   throw new Error(`API Error: (${response.status}) ${data.code} - ${data.message}`);
    // }

    return new LoginModel(data);
  } catch (error) {
    if (error.response) {
      const data = error.response.data;
      console.error(`API Error: (${data.status}) ${data.code} - ${data.message}`);
      return new LoginModel(data);
    } else {
      console.error('Error:', error.message || error);
    }
    throw error;
  }
};
