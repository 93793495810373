import React, { useState, useEffect } from 'react';
import { Box, Container, Grid, Paper, Typography, CircularProgress, Divider, Modal, IconButton, AppBar, Toolbar } from '@mui/material';
import { getProductList } from '../../services/repositories/ProductListService';
import ParallaxHeader from '../parallaxheader/ParallaxHeader';
import topBanner from '../../assets/images/topbanner.jpg';
import { API_ENDPOINTS } from '../../config/ApiConfig';
import CloseIcon from '@mui/icons-material/Close';

function ProductList() {
  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const data = await getProductList();
        setProducts(data.data);
      } catch (error) {
        console.error('Error fetching products:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const handleOpen = (product) => {
    setSelectedProduct(product);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedProduct(null);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          // width: '100%',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!products || products.length === 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Typography variant="h6">No Products Available</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', backgroundColor: '#fff', minHeight: '100vh' }}>
      <ParallaxHeader title="Product List" backgroundImage={topBanner} />
      <Container
        sx={{
          // maxWidth: 'lg',
          padding: { xs: '16px', sm: '32px' },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Grid container spacing={4} alignItems="center" justifyContent="center">
          {products.map((product) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={product.id}>
            <Paper
              elevation={3}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '16px',
                backgroundColor: '#fff',
                borderRadius: '10px',
                textAlign: 'center',
                height: '400px',
                maxWidth: '250px',
                overflow: 'hidden',
                cursor: 'pointer',
                margin: '0 auto', 
              }}
              onClick={() => handleOpen(product)}
            >
              <img
                src={`${API_ENDPOINTS.displayImage}${product.imgPath}`}
                alt={product.title}
                style={{
                  width: '100%',
                  height: '280px',
                  objectFit: 'contain',
                  borderRadius: '10px',
                  marginBottom: '16px',
                }}
              />
              <Divider sx={{ width: '100%', my: 1 }} />
              <Box sx={{ width: '100%', padding: '0 8px' }}>
                <Typography
                  variant="h6"
                  sx={{
                    marginTop: '16px',
                    overflow: 'hidden',
                    textAlign: 'center',
                  }}
                >
                  {product.title}
                </Typography>
              </Box>
            </Paper>
          </Grid>
          ))}
        </Grid>
      </Container>

      <Modal
        open={open}
        onClose={handleClose}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box
          sx={{
            // width: '100%',
            // maxWidth: '800px',
            maxHeight: '80vh',
            bgcolor: 'background.paper',
            boxShadow: 5,
            overflowY: 'auto',
            overflowX: 'hidden',
            // outline: 'none',
            // '@media (max-width: 600px)': {
            //   width: '95%',
            // },
          }}
        >
          {selectedProduct && (
            <>
              <AppBar position="static" sx={{ backgroundColor: 'rgba(137,117,44,0.7)' }} elevation={0}>
                <Toolbar>
                  <Typography variant="h6" sx={{ flexGrow: 1 }}>
                    Description
                  </Typography>
                  <IconButton edge="end" color="inherit" onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </Toolbar>
              </AppBar>
              <Box sx={{ padding: '16px' }}>
                <div dangerouslySetInnerHTML={{ __html: selectedProduct.description }} />
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </Box>
  );
}

export default ProductList;
