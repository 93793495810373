import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import m1 from '../../assets/images/mid3/1.jpg';  
import m2 from '../../assets/images/mid3/2.jpg';

function BigCard({ image, title, description }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        overflow: 'hidden', 
      }}
    >
      <Box
        component="img"
        src={image}
        alt="big-card-image"
        sx={{
          width: '100%',
          maxWidth: '100%', 
          height: 'auto', 
          objectFit: 'cover',
        }}
      />
      <Box>
        <Typography
          variant="h6"
          sx={{
            color: '#333',
            marginBottom: '8px',
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontWeight: 100,
            color: '#666',
            lineHeight: '1.5',
          }}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );
}

function Mid3() {
  return (
    <Box
      sx={{
        mr: { xs: 2, sm: 15, md: 15, lg: 20, xl: 30 },
        ml: { xs: 2, sm: 15, md: 15, lg: 20, xl: 30 },
      }}
    >
      <Box
        sx={{
          paddingBottom: '50px',
          textAlign: 'center',
          width: '100%',
          overflowX: 'hidden', 
          boxSizing: 'border-box',
          mx: 'auto', 
          maxWidth: { xs: '100%', md: '90%' }, 
        }}
      >
        <Grid container spacing={4} sx={{ marginTop: '50px' }}>
          <Grid item xs={12} md={6}>
            <BigCard
              image={m1}
              title="EXCLUSIVE COLLECTION"
              description="Discover our exclusive collection of timeless pieces crafted with precision and finesse."
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <BigCard
              image={m2}
              title="LUXURY PIECES"
              description="Indulge in luxury with our specially curated selection of high-end jewelry."
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default Mid3;
