import { useState, useEffect, useRef } from 'react';
//Renamed
const UseIntersectionObserver = (options) => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const handleIntersection = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting !== isIntersecting) {
          setIsIntersecting(entry.isIntersecting);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);
    const node = ref.current; 
    if (node) {
      observer.observe(node);
    }

    return () => {
      if (node) {
        observer.unobserve(node);
      }
    };
  }, [options, isIntersecting]); 

  return [ref, isIntersecting];
};

export default UseIntersectionObserver;
