import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { getHomePopUp } from '../../services/repositories/HomePopUpService'; 
import { API_ENDPOINTS } from '../../config/ApiConfig';

const TIMEOUT_DURATION = 5000;

const PopUpDialog = ({ open, onClose }) => {
  const [imageSrc, setImageSrc] = useState('');
  const [description, setDescription] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [isOpen, setIsOpen] = useState(false); 

  useEffect(() => {
    if (open) {
      const fetchData = async () => {
        const controller = new AbortController(); 
        const timeoutId = setTimeout(() => controller.abort(), TIMEOUT_DURATION); 

        try {
          const data = await getHomePopUp({ signal: controller.signal }); 
          const imageUrl = data.data.imgPath ? `${API_ENDPOINTS.displayImage}${data.data.imgPath}` : '';
          setDescription(data.data.description || '<p>Loading</p>'); 

          if (imageUrl) {
            const img = new Image();
            img.src = imageUrl;
            img.onload = () => {
              setImageSrc(imageUrl);
              setIsLoaded(true); 
              setIsOpen(true); // Set isOpen to true only if image is successfully loaded
            };
            img.onerror = () => {
              setImageSrc(''); 
              setIsLoaded(true); 
            };
          } else {
            setIsLoaded(true); 
          }

          console.log('Image URL:', imageUrl); 
        } catch (error) {
          if (error.name === 'AbortError') {
            console.error('Request timed out.');
          } else {
            console.error('Failed to fetch popup data:', error.message || error);
          }
          setIsLoaded(true); 
          setIsOpen(false); // Ensure dialog is closed if there was an error
        } finally {
          clearTimeout(timeoutId); 
        }
      };

      fetchData();
    } else {
      setIsLoaded(false);
      setIsOpen(false);
    }
  }, [open]);

  return (
    <Dialog 
      open={isOpen && isLoaded} 
      onClose={onClose} 
      PaperProps={{ style: { backgroundColor: 'rgba(255, 248, 240, 0.9)', position: 'relative' } }}
    >
      <DialogContent style={{ paddingBottom: 60, paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}>
        {imageSrc ? (
          <img 
            src={imageSrc} 
            alt="Popup" 
            style={{ width: '100%' }} 
          />
        ) : (
          <div 
            dangerouslySetInnerHTML={{ __html: description }} 
            style={{ textAlign: 'center' }}
          />
        )}
        <IconButton
          onClick={onClose}
          style={{
            position: 'absolute',
            bottom: 16,
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: '#800000',
            color: 'white',
            borderRadius: '50%',
            width: 32,
            height: 32,
            padding: 0,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
};

export default PopUpDialog;
