import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Grid, Paper, Typography, CircularProgress, Button, Divider } from '@mui/material';
import { getProfile } from '../../services/repositories/ProfileService';
import ParallaxHeader from '../parallaxheader/ParallaxHeader';
import topBanner from '../../assets/images/topbanner.jpg';
import PasswordChangeDialog from '../passwordchangedialog/PasswordChangeDialog'; 

function Profile() {
  const navigate = useNavigate();
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isPasswordDialogOpen, setIsPasswordDialogOpen] = useState(false); 

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const data = await getProfile(navigate);
        setProfile(data.data);
      } catch (error) {
        console.error('Error fetching profile:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [navigate]);

  const handlePasswordChangeSuccess = () => {
    // Not sure yet
  };

  const handleOpenPasswordChangeDialog = () => {
    setIsPasswordDialogOpen(true);
  };

  const handleClosePasswordChangeDialog = () => {
    setIsPasswordDialogOpen(false);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center', 
          justifyContent: 'center',
          minHeight: '100vh',
          width: '100%', 
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!profile) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh', 
        }}
      >
        <Typography variant="h6">No Data Available</Typography>
      </Box>
    );
  }

  const fields = [
    // { label: 'User ID', value: String(profile.customer.userId) }, 
    { label: 'Username', value: profile.email },
    { label: 'Name', value: profile.name },
    { label: 'Referral Code', value: profile.referralCode },
    { label: 'Phone', value: profile.customer.phone },
    // { label: 'Upline ID', value: profile.customer.uplineId },
    { label: 'Wallet Balance', value: `₹${profile.customer.availablePurchaseAmt}` },
    // { label: 'Experience Balance', value: `₹${profile.customer.experienceBalance}` },
    { label: 'Ongoing Task Progress', value: `${profile.customer.todayOrderNum}/${profile.customer.dailyOrderMax}` },
    // { label: 'Today Order Number', value: profile.customer.todayOrderNum },
    { label: 'Voucher Amount', value: `₹${profile.customer.todayCommAmt}` },
    { label: 'Credit Score', value: `${profile.customer.creditScore}` },
  ];

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', backgroundColor: '#fff', minHeight: '100vh' }}>
        <ParallaxHeader title="Account Details" backgroundImage={topBanner} />
        <Box sx={{ flexGrow: 1, padding: { xs: '16px', sm: '32px' } }}>
          <Container>
            <Grid container spacing={4}>
              {fields.map((field, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={12} sm={6}>
                    <Paper elevation={3} sx={{ padding: '16px', backgroundColor: '#fff', borderRadius: '30px' }}>
                      <Typography variant="h6" sx={{ marginBottom: '8px' }}>
                        {field.label}
                      </Typography>
                      <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px' }}>
                        {field.value}
                      </Typography>
                    </Paper>
                  </Grid>
                  {(field.label === 'Phone' && fields[index + 1]?.label === 'Wallet Balance') && (
                    <Grid item xs={12}>
                      <Divider sx={{ my: 4, width: '100%' }} />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            </Grid>
          </Container>
        </Box>
        <Grid item xs={12}>
              <Divider sx={{ my: 4, width: '100%' }} />
         </Grid>
        <Box sx={{ padding: '16px', display: 'flex', justifyContent: 'center', mb: 4 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenPasswordChangeDialog}
            sx={{ 
              borderRadius: '20px',
              backgroundColor: '#581a7d', 
              '&:hover': {
                backgroundColor: '#260b36', 
              }
            }}
          >
            Change Password
          </Button>
        </Box>
      </Box>

      <PasswordChangeDialog
        open={isPasswordDialogOpen}
        onClose={handleClosePasswordChangeDialog}
        onPasswordChangeSuccess={handlePasswordChangeSuccess}
      />
    </>
  );
}

export default Profile;
