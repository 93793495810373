class ProfileModel {
  constructor({ code = '', data = {}, message = '' } = {}) {
    this.code = code;
    this.message = message;
    this.data = data ? new ProfileDataModel(data) : {};
  }
}

class ProfileDataModel {
  constructor({
    id = '',
    name = '',
    email = '',
    email_verified_at = '',
    last_login = '',
    login_ip = '',
    referral_code = '',
    created_at = '',
    updated_at = '',
    deleted_at = '',
    team_id = '',
    customer = {},
  } = {}) {
    this.id = id;
    this.name = name;
    this.email = email;
    this.emailVerifiedAt = email_verified_at;
    this.lastLogin = last_login;
    this.loginIp = login_ip;
    this.referralCode = referral_code;
    this.createdAt = created_at;
    this.updatedAt = updated_at;
    this.deletedAt = deleted_at;
    this.teamId = team_id;
    this.customer = customer ? new CustomerModel(customer) : {};
  }
}

class CustomerModel {
  constructor({
    id = '',
    user_id = '',
    phone = '',
    upline_id = '',
    wallet_balance = '',
    experience_balance = '',
    daily_order_max = '',
    today_order_num = '',
    today_comm_amt = '',
    locked_amt = '',
    set_continuous_order_num = '',
    balance_after_continuous_order = '',
    sign_in_number = '',
    lucky_draw_number = '',
    lucky_draw_amount = '',
    status = '',
    pin = '',
    bank_owner_name = '',
    bank_name = '',
    bank_owner_phone = '',
    bank_owner_card_no = '',
    bank_address = '',
    bank_ifsc = '',
    created_at = '',
    updated_at = '',
    popup_title = '',
    popup_desc = '',
    available_puchase_amt = '',
    available_withdraw_amt = '',
    credit_score = '',
    disabled_booking = 0,
    disabled_booking_title = '',
    disabled_booking_description = '',
    show_mlm = 0,
    disabled_withdrawal = 0,
	  disabled_withdrawal_title = '',
	  disabled_withdrawal_description = '',
  } = {}) {
    this.id = id;
    this.userId = user_id;
    this.phone = phone;
    this.uplineId = upline_id;
    this.walletBalance = wallet_balance;
    this.experienceBalance = experience_balance;
    this.dailyOrderMax = daily_order_max;
    this.todayOrderNum = today_order_num;
    this.todayCommAmt = today_comm_amt;
    this.lockedAmt = locked_amt;
    this.setContinuousOrderNum = set_continuous_order_num;
    this.balanceAfterContinuousOrder = balance_after_continuous_order;
    this.signInNumber = sign_in_number;
    this.luckyDrawNumber = lucky_draw_number;
    this.luckyDrawAmount = lucky_draw_amount;
    this.status = status;
    this.pin = pin;
    this.bankOwnerName = bank_owner_name;
    this.bankName = bank_name;
    this.bankOwnerPhone = bank_owner_phone;
    this.bankOwnerCardNo = bank_owner_card_no;
    this.bankAddress = bank_address;
    this.bankIfsc = bank_ifsc;
    this.createdAt = created_at;
    this.updatedAt = updated_at;
    this.popupTitle = popup_title;
    this.popupDesc = popup_desc;
    this.availablePurchaseAmt = available_puchase_amt;
    this.availableWithdrawAmt = available_withdraw_amt;
    this.creditScore = credit_score;
    this.disabledBooking = disabled_booking;
    this.disabledBookingTitle = disabled_booking_title;
    this.disabledBookingDescription = disabled_booking_description;
    this.showMlm = show_mlm;
    this.disabledWithdrawal = disabled_withdrawal;
    this.disabledWithdrawalTitle = disabled_withdrawal_title;
    this.disabledWithdrawalDescription = disabled_withdrawal_description;
  }
}

export default ProfileModel;
