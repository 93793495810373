import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Container, Modal, IconButton, AppBar, Toolbar, Typography } from '@mui/material';
import { getPromos } from '../../services/repositories/PromoService';
import { API_ENDPOINTS } from '../../config/ApiConfig';
import ParallaxHeader from '../parallaxheader/ParallaxHeader'; 
import CloseIcon from '@mui/icons-material/Close';
import topBanner from '../../assets/images/topbanner.jpg';

function Promo() {
  const [promos, setPromos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedPromo, setSelectedPromo] = useState(null);

  const handleOpen = (promo) => {
    setSelectedPromo(promo);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedPromo(null);
  };

  useEffect(() => {
    const fetchPromos = async () => {
      try {
        const data = await getPromos();
        setPromos(data.data);
      } catch (error) {
        console.error('Error fetching promos:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPromos();
  }, []);

  return (
    <Box sx={{ backgroundColor: '#fff' }}> 
      <ParallaxHeader title="Events" backgroundImage={topBanner} />
      
      <Container sx={{ mt: 4, padding: { xs: '0', sm: '0 16px', md: '0 32px' } }}>
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center', 
              justifyContent: 'center',
              minHeight: '100vh',
              width: '100%', 
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px', 
              width: '100%',
            }}
          >
            {promos.map((promo) => (
              <Box
                key={promo.id}
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <img
                  src={`${API_ENDPOINTS.displayImage}${promo.imgPath}`}
                  alt={`Promotion ${promo.id}`}
                  onClick={() => handleOpen(promo)}
                  style={{ 
                    // width: '100%',
                    // maxWidth: 'calc(100% - 32px)',
                    maxWidth: '40vh',
                    height: 'auto',
                    padding: '16px 0', 
                    cursor: 'pointer',
                  }}
                />
              </Box>
            ))}
          </Box>
        )}
      </Container>

      <Modal
        open={open}
        onClose={handleClose}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box
          sx={{
            width: '80%',
            maxWidth: '800px', 
            maxHeight: '80vh',
            bgcolor: 'background.paper',
            boxShadow: 5,
            overflowY: 'auto',  
            overflowX: 'hidden', 
            outline: 'none',
            '@media (max-width: 600px)': {
              width: '95%',
            },
          }}
        >
          {selectedPromo && (
            <>
              <AppBar position="static" sx={{ backgroundColor: 'rgba(137,117,44,0.7)' }} elevation={0}>
                <Toolbar>
                  <Typography variant="h6" sx={{ flexGrow: 1 }}>
                    {selectedPromo.title}
                  </Typography>
                  <IconButton edge="end" color="inherit" onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </Toolbar>
              </AppBar>
              <Box sx={{ padding: '16px' }}>
                <div dangerouslySetInnerHTML={{ __html: selectedPromo.description }} />
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </Box>
  );
}

export default Promo;
