import React from 'react';
import { Box, Grid } from '@mui/material';
import img1 from '../../assets/images/mid4/1.png';  
import img2 from '../../assets/images/mid4/2.png';
import img3 from '../../assets/images/mid4/3.png';
import img4 from '../../assets/images/mid4/4.png';
import img5 from '../../assets/images/mid4/5.png';

function Mid4() {
  return (
    <Box
      sx={{
        mr: { xs: 20, sm: 40 },
        ml: { xs: 20, sm: 40 },
      }}
    >
      <Box
        sx={{
          textAlign: 'center',
          width: '100%',
          boxSizing: 'border-box',
        }}
      >
        <Grid 
          container 
          spacing={4}
          justifyContent="center"  
        >
          <Grid item xs={12} sm={4} md={2}>
            <Box
              component="img"
              src={img1}
              alt="image-1"
              sx={{
                width: '100%',
                height: 'auto',
                objectFit: 'cover',
                aspectRatio: '1',
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Box
              component="img"
              src={img2}
              alt="image-2"
              sx={{
                width: '100%',
                height: 'auto',
                objectFit: 'cover',
                aspectRatio: '1',
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Box
              component="img"
              src={img3}
              alt="image-3"
              sx={{
                width: '100%',
                height: 'auto',
                objectFit: 'cover',
                aspectRatio: '1',
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Box
              component="img"
              src={img4}
              alt="image-4"
              sx={{
                width: '100%',
                height: 'auto',
                objectFit: 'cover',
                aspectRatio: '1',
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Box
              component="img"
              src={img5}
              alt="image-5"
              sx={{
                width: '100%',
                height: 'auto',
                objectFit: 'cover',
                aspectRatio: '1',
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default Mid4;
