class CustomerServiceLinkModel {
    constructor({ code = '', data = {}, message = '' } = {}) {
      this.code = code;
      this.message = message;
      this.data = data ? new CustomerServiceLinkDataModel(data) : {};
    }
  }
  
  class CustomerServiceLinkDataModel {
    constructor({
      id = '',
      key = '',
      value = '',
      created_at = '',
      updated_at = '',
    } = {}) {
      this.id = id;
      this.key = key;
      this.value = value;
      this.createdAt = created_at;
      this.updatedAt = updated_at;
    }
  }
  
  export default CustomerServiceLinkModel;
  