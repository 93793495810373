import React, { useState } from 'react';
import { TextField, IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { postRegister } from '../../services/repositories/RegisterService'; 
import CustomDialog from '../customdialog/CustomDialog';

function RegisterDialog({ open, onClose, onRegisterSuccess }) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);

  const handleRegister = async () => {
    setLoading(true);
    setError('');

    if (!name || !email || !password || !confirmPassword || !phone || !referralCode) {
      setError('All fields are required.');
      setLoading(false);
      return;
    }

    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      setLoading(false);
      return;
    }

    if (!/^\d+$/.test(phone)) {
      setError('Phone number must contain only digits.');
      setLoading(false);
      return;
    }

    if (phone.length < 10) {
      setError('Phone number must be valid.');
      setLoading(false);
      return;
    }

    try {
      const response = await postRegister({
        name,
        email,
        password,
        phone,
        referral_code: referralCode,
      });

      if (response && response.code === 2000) {
        setIsSuccessDialogOpen(true);
      } else {
        const errorMessage = 
          response.data?.name?.[0] || 
          response.data?.email?.[0] || 
          response.data?.password?.[0] || 
          response.data?.phone?.[0] || 
          response.data?.referralCode?.[0] || 
          response.message;
          console.log(response.data);
        setError(errorMessage);
      }
    } catch (err) {
      setError(err.message || 'Registration failed. Please try again.');
      console.error('Error during registration:', err.message || err);
    } finally {
      setLoading(false);
    }
  };

  const handleSuccessDialogClose = () => {
    setIsSuccessDialogOpen(false);
    onRegisterSuccess();
    onClose();
  };

  return (
    <>
      <CustomDialog
        open={open}
        onClose={onClose}
        title="Register"
        onSubmit={handleRegister}
        submitLabel="Register"
        submitLoading={loading}
        submitDisabled={loading}
      >
        <TextField
          label="Username"
          type="text"
          fullWidth
          variant="outlined"
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          InputProps={{
            sx: {
              fontFamily: 'Hind Madurai',
              color: '#581a7d',
            },
          }}
          InputLabelProps={{
            sx: {
              fontFamily: 'Hind Madurai',
              color: '#581a7d',
              '&.Mui-focused': {
                color: '#581a7d',
              },
            },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#581a7d',
              },
              '&:hover fieldset': {
                borderColor: '#581a7d',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#581a7d',
              },
            },
          }}
        />
        <TextField
          label="Name"
          type="text"
          fullWidth
          variant="outlined"
          margin="normal"
          value={name}
          onChange={(e) => setName(e.target.value)}
          InputProps={{
            sx: {
              fontFamily: 'Hind Madurai',
              color: '#581a7d',
            },
          }}
          InputLabelProps={{
            sx: {
              fontFamily: 'Hind Madurai',
              color: '#581a7d',
              '&.Mui-focused': {
                color: '#581a7d',
              },
            },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#581a7d',
              },
              '&:hover fieldset': {
                borderColor: '#581a7d',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#581a7d',
              },
            },
          }}
        />
        <TextField
          label="Password"
          type={showPassword ? 'text' : 'password'}
          fullWidth
          variant="outlined"
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
            sx: {
              fontFamily: 'Hind Madurai',
              color: '#581a7d',
            },
          }}
          InputLabelProps={{
            sx: {
              fontFamily: 'Hind Madurai',
              color: '#581a7d',
              '&.Mui-focused': {
                color: '#581a7d',
              },
            },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#581a7d',
              },
              '&:hover fieldset': {
                borderColor: '#581a7d',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#581a7d',
              },
            },
          }}
        />
        <TextField
          label="Confirm Password"
          type={showPassword ? 'text' : 'password'}
          fullWidth
          variant="outlined"
          margin="normal"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
            sx: {
              fontFamily: 'Hind Madurai',
              color: '#581a7d',
            },
          }}
          InputLabelProps={{
            sx: {
              fontFamily: 'Hind Madurai',
              color: '#581a7d',
              '&.Mui-focused': {
                color: '#581a7d',
              },
            },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#581a7d',
              },
              '&:hover fieldset': {
                borderColor: '#581a7d',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#581a7d',
              },
            },
          }}
        />
        <TextField
          label="Phone"
          type="tel"
          fullWidth
          variant="outlined"
          margin="normal"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          inputProps={{ 
            pattern: '[0-9]*', 
            inputMode: 'numeric' 
          }}  
          onKeyPress={(e) => {
            if (!/[0-9]/.test(e.key)) {
              e.preventDefault();
            }
          }}
          InputProps={{
            sx: {
              fontFamily: 'Hind Madurai',
              color: '#581a7d',
            },
          }}
          InputLabelProps={{
            sx: {
              fontFamily: 'Hind Madurai',
              color: '#581a7d',
              '&.Mui-focused': {
                color: '#581a7d',
              },
            },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#581a7d',
              },
              '&:hover fieldset': {
                borderColor: '#581a7d',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#581a7d',
              },
            },
          }}
        />
        <TextField
          label="Referral Code"
          type="text"
          fullWidth
          variant="outlined"
          margin="normal"
          value={referralCode}
          onChange={(e) => setReferralCode(e.target.value)}
          InputProps={{
            sx: {
              fontFamily: 'Hind Madurai',
              color: '#581a7d',
            },
          }}
          InputLabelProps={{
            sx: {
              fontFamily: 'Hind Madurai',
              color: '#581a7d',
              '&.Mui-focused': {
                color: '#581a7d',
              },
            },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#581a7d',
              },
              '&:hover fieldset': {
                borderColor: '#581a7d',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#581a7d',
              },
            },
          }}
        />
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </CustomDialog>

      <CustomDialog
        open={isSuccessDialogOpen}
        onClose={handleSuccessDialogClose}
        title="Successful"
        onSubmit={handleSuccessDialogClose}
        submitLabel="Ok"
      >
        <p>Please login to your account</p>
      </CustomDialog>
    </>
  );
}

export default RegisterDialog;
