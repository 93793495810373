import axios from 'axios';
import { API_ENDPOINTS } from '../../config/ApiConfig';
import ProfileModel from '../models/ProfileModel';
import { handleLogout } from '../../utils/LogoutHelper';

export const getProfile = async (navigate) => {

  try {
    const response = await axios.get(API_ENDPOINTS.getProfile, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      }
    });

    const data = response.data;

    return new ProfileModel(data);
  } catch (error) {
    if (error.response) {
      const data = error.response.data;
      console.error(`API Error: (${data.status}) ${data.code} - ${data.message}`);
      
      if (!handleLogout(data.code, navigate)) {
        return new ProfileModel(data);
      }
    } else {
      console.error('Error:', error.message || error);
    }
    throw error;
  }
};
