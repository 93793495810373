import React, { useState } from 'react';
import { Box, Container, Divider, TextField, Button, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import ParallaxHeader from '../parallaxheader/ParallaxHeader'; 
import topBanner from '../../assets/images/topbanner.jpg';
import CustomDialog from '../customdialog/CustomDialog';

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#581a7d',
    },
    '&:hover fieldset': {
      borderColor: '#581a7d',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#581a7d',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#581a7d',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#581a7d',
  },
  '& .MuiInputBase-input': {
    fontFamily: 'Hind Madurai',
    color: '#581a7d',
    textAlign: 'right', 
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '20px',
  border: `1px solid #581a7d`,
  color: '#581a7d',
  // fontFamily: 'Hind Madurai, sans-serif',
  padding: theme.spacing(1, 2),
  margin: theme.spacing(0.5),
  '&:hover': {
    backgroundColor: 'rgba(82, 7, 12, 0.1)',
  },
}));

const DepositHistoryButton = styled(Button)(() => ({
  textTransform: 'none',
  color: '#581a7d',
  fontFamily: 'Hind Madurai, sans-serif',
  padding: 0,
  border: 'none',
  background: 'none',
  textDecoration: 'underline',
  '&:hover': {
    background: 'none',
    textDecoration: 'underline',
  },
}));

function Deposit() {
  const [amount, setAmount] = useState('0.00');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const navigate = useNavigate();

  const handleAmountChange = (event) => {
    const value = event.target.value;
    
    const regex = /^\d*\.?\d{0,2}$/;
    if (regex.test(value)) {
      setAmount(value);
    }
  };

  const handleAmountButtonClick = (value) => {
    setAmount(value);
  };

  const handleContinue = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    navigate('/customer-service');
  };

  const handleHistoryClick = () => {
    navigate('/bank-statement');
  };

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <ParallaxHeader title="Deposit" backgroundImage={topBanner} />
      <Container sx={{ flex: 1, mt: 4, position: 'relative', paddingTop: '80px' }}>
        <DepositHistoryButton onClick={handleHistoryClick}>
          Deposit History
        </DepositHistoryButton>
        <StyledTextField
          label="Amount"
          value={amount}
          fullWidth
          variant="outlined"
          onChange={handleAmountChange}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
          <Box sx={{ display: 'flex' }}>
            <StyledButton onClick={() => handleAmountButtonClick('10000.00')}>₹10,000.00</StyledButton>
            <StyledButton onClick={() => handleAmountButtonClick('20000.00')}>₹20,000.00</StyledButton>
            <StyledButton onClick={() => handleAmountButtonClick('30000.00')}>₹30,000.00</StyledButton>
          </Box>
          <StyledButton onClick={() => handleAmountButtonClick('50000.00')}>₹50,000.00</StyledButton>
        </Box>
      </Container>
      <Divider sx={{ my: 4, width: '100%' }} />
      <Box sx={{ padding: '16px', display: 'flex', justifyContent: 'center', mb: 4 }}>
        <Button
          variant="contained"
          color="primary"
          sx={{ borderRadius: '20px', backgroundColor: '#581a7d', color: '#fff' }}
          onClick={handleContinue}
        >
          Continue
        </Button>
      </Box>
      <CustomDialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        title="Assistance Needed"
        onSubmit={handleDialogClose}
        submitLabel="Ok"
      >
        <Typography>Please contact customer service for further assistance.</Typography>
      </CustomDialog>
    </Box>
  );
}

export default Deposit;
