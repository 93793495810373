import axios from 'axios';
import { API_ENDPOINTS } from '../../config/ApiConfig'; 
import OrderListModel from '../models/OrderListModel';
import { handleLogout } from '../../utils/LogoutHelper';

export const getOrderList = async (navigate) => {
  try {
    const response = await axios.get(API_ENDPOINTS.getOrderList, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
          }
    });

    const data = response.data;
    
    return new OrderListModel(data);
  } catch (error) {
    if (error.response) {
      const data = error.response.data;
      console.error(`API Error: (${data.status}) ${data.code} - ${data.message}`);
      if (!handleLogout(data.code, navigate)) {
        return new OrderListModel(data);
      }
    } else {
      console.error('Error:', error.message || error);
    }
    throw error;
  }
};
