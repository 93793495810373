import React, { useState } from 'react';
import { Box, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress } from '@mui/material';
import closedImage from '../../assets/images/luckydraw/closed.png';
import openedImage from '../../assets/images/luckydraw/opened.png';
import { useNavigate } from 'react-router-dom';
import { postSubmitLuckyDraw } from '../../services/repositories/SubmitLuckyDrawService';

function CustomPopup({ amount, onClose }) {
  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>
        <Typography variant="h6">Congratulations!</Typography>
      </DialogTitle>
      <DialogContent>
        {/* <Typography variant="body1">You won ₹{amount}!</Typography> */}
        <Typography variant="body1">You won a gift!</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function LuckyDrawModal({ amount, open, onClose }) {
  const [selectedBox, setSelectedBox] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isRan, setIsRan] = useState(false);
  const navigate = useNavigate();

  const handleBoxClick = async ({isClose, boxNumber}) => {
    if (selectedBox === null && !loading && !isRan) {
      setSelectedBox(boxNumber);
      setLoading(true);
      setIsRan(true);
      try {
        // const response = await postSubmitOrder();
        await postSubmitLuckyDraw({is_close_clicked: isClose, navigate: navigate});
        setLoading(false);
        setShowPopup(true);
        setError(null);
      } catch (err) {
        setLoading(false);
        setError('Failed to submit. Please try again.');
        setSelectedBox(null);
        setIsRan(false);
      }
    }
  };

  const handlePopupClose = () => {
    setShowPopup(false);
    setSelectedBox(null);
    onClose();
  };

  const handleDialogClose = () => {
    if (!loading) {
      setSelectedBox(null);
      onClose();
    }
  };

  return (
    <>
      <Dialog open={open} onClose={handleDialogClose} maxWidth="lg" fullWidth>
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'rgba(0, 0, 0, 0.2)',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            pointerEvents: loading ? 'none' : 'auto',  
          }}
        >
          <DialogContent
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: 0,
              padding: 0,
              height: 'calc(100% - 56px)',
              overflow: 'hidden',
              '@media (max-width: 600px)': {
                padding: '0 8px', 
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                flexWrap: 'nowrap', 
                overflowX: 'auto',
                '@media (max-width: 600px)': {
                  '& > *': {
                    margin: '0 8px', 
                  },
                },
              }}
            >
              {Array.from({ length: 3 }).map((_, index) => (
                <Box
                  key={index}
                  sx={{
                    width: '150px',
                    height: '150px',
                    backgroundImage: `url(${selectedBox === index ? openedImage : closedImage})`,
                    backgroundSize: 'cover',
                    cursor: 'pointer',
                    margin: '0 15px',
                    transition: 'transform 0.3s ease',
                    '&:hover': {
                      animation: 'rotateLeft 0.3s alternate',
                    },
                    '@keyframes rotateLeft': {
                      from: { transform: 'rotateY(-15deg)' },
                      to: { transform: 'rotateY(15deg)' },
                    },
                    '@keyframes rotateRight': {
                      from: { transform: 'rotateY(15deg)' },
                      to: { transform: 'rotateY(-15deg)' },
                    },
                    '@media (max-width: 600px)': {
                      width: '120px',
                      height: '120px',
                      margin: '0 8px', 
                    },
                  }}
                  onClick={() => handleBoxClick({isClose: 0, boxNumber: index})}
                />
              ))}
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              // onClick={handleDialogClose}
              onClick={() => handleBoxClick({isClose: 1, boxNumber: null})}
              color="primary"
              disabled={loading}
              sx={{
                backgroundColor: 'white',
                borderRadius: '50%',
                padding: '8px',
                minWidth: '40px',
                '&:hover': {
                  backgroundColor: 'lightgray',
                },
              }}
            >
              ❌
            </Button>
          </DialogActions>
        </Box>

        {loading && (
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              zIndex: 9999,
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Dialog>

      {showPopup && !error && <CustomPopup amount={amount} onClose={handlePopupClose} />}
      {error && (
        <Dialog open={true} onClose={handlePopupClose}>
          <DialogTitle>
            <Typography variant="h6">Error</Typography>
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1">{error}</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handlePopupClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default LuckyDrawModal;
