import axios from 'axios';
import { API_ENDPOINTS } from '../../config/ApiConfig';
import GeneralModel from '../models/GeneralModel';
import { handleLogout } from '../../utils/LogoutHelper';

export const postAddBank = async ({
  pin,
  bank_owner_name,
  bank_name,
  bank_owner_phone,
  bank_owner_card_no,
  // bank_address,
  bank_ifsc,
  navigate,
}) => {
  try {
    const response = await axios.post(API_ENDPOINTS.postAddBank, {
      pin,
      bank_owner_name,
      bank_name,
      bank_owner_phone,
      bank_owner_card_no,
      // bank_address,
      bank_ifsc,
    }, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      }
    });

    const data = response.data;

    return new GeneralModel(data);
  } catch (error) {
    if (error.response) {
      const data = error.response.data;
      console.error(`API Error: (${data.status}) ${data.code} - ${data.message}`);
      if (!handleLogout(data.code, navigate)) {
        return new GeneralModel(data);
      }
    } else {
      console.error('Error:', error.message || error);
    }
    throw error;
  }
};
