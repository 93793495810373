import axios from 'axios';
import { API_ENDPOINTS } from '../../config/ApiConfig';
import CustomerServiceLinkModel from '../models/CustomerServiceLinkModel';

export const getCustomerServiceLink = async () => {
  try {
    const response = await axios.get(API_ENDPOINTS.getCustomerServiceLink, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      }
    });
    const data = response.data;
    
    return new CustomerServiceLinkModel(data);
  } catch (error) {
    if (error.response) {
      const data = error.response.data;
      console.error(`API Error: (${data.status}) ${data.code} - ${data.message}`);
      return new CustomerServiceLinkModel(data);
    } else {
      console.error('Error:', error.message || error);
    }
    throw error;
  }
};
