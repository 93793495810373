class BookingDetailModel {
  constructor({ code = '', data = {}, message = '' } = {}) {
    this.code = code;
    this.message = message;
    this.data = data ? new BookingDetailDataModel(data) : {};
  }
}

class BookingDetailDataModel {
  constructor({
    id = '',
    order_code = '',
    cust_id = '',
    product_id = '',
    order_total_num = 0,
    order_completed_num = 0,
    product_amt = '',
    product_comm = '',
    status = 0,
    created_at = '',
    updated_at = null,
    lucky_draw_amount = 0,
    product = {} 
  } = {}) {
    this.id = id;
    this.orderCode = order_code;
    this.customerId = cust_id;
    this.productId = product_id;
    this.orderTotalNum = order_total_num;
    this.orderCompletedNum = order_completed_num;
    this.productAmt = product_amt;
    this.productComm = product_comm;
    this.status = status;
    this.createdAt = created_at;
    this.updatedAt = updated_at;
    this.luckyDrawAmount = lucky_draw_amount;
    this.product = product ? new ProductModel(product) : {};
  }
}

class ProductModel {
  constructor({
    id = '',
    title = '',
    description = '',
    img_path = '',
    created_at = '',
    updated_at = '',
    deleted_at = null
  } = {}) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.imgPath = img_path;
    this.createdAt = created_at;
    this.updatedAt = updated_at;
    this.deletedAt = deleted_at;
  }
}

export default BookingDetailModel;
