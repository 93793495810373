import axios from 'axios';
import { API_ENDPOINTS } from '../../config/ApiConfig';
import PromoModel from '../models/PromoModel';

export const getPromos = async () => {
  try {
    const response = await axios.get(API_ENDPOINTS.getPromos);
    const data = response.data;

    // if (response.status === 200) {
    //   const promoData = new PromoModel(data);
    //   return promoData;
    // } else if (response.status === 404) {
    //   console.error(`API Error: (${response.status}) ${data.code} - ${data.message}`);
    //   const promoData = new PromoModel(data);
    //   return promoData;
    // } else {
    //   throw new Error(`API Error: (${response.status}) ${data.code} - ${data.message}`);
    // }

    return new PromoModel(data);
  } catch (error) {
    if (error.response) {
      const data = error.response.data;
      console.error(`API Error: (${data.status}) ${data.code} - ${data.message}`);
      return new PromoModel(data);
    } else {
      console.error('Error:', error.message || error);
    }
    throw error;
  }
};
