import React, { useState } from 'react';
import { TextField, IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { postPasswordChange } from '../../services/repositories/PasswordChangeService'; 
import CustomDialog from '../customdialog/CustomDialog';

function PasswordChangeDialog({ open, onClose, onPasswordChangeSuccess }) {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);

  const handlePasswordChange = async () => {
    setLoading(true);
    setError('');

    try {
      const response = await postPasswordChange({
        old_password: oldPassword,
        new_password: newPassword,
      });

      if (response && response.code === 2000) {
        setIsSuccessDialogOpen(true);
        onPasswordChangeSuccess();
       
        setTimeout(() => {
          setIsSuccessDialogOpen(false);
          onClose();
        }, 2000);
      } else {
        setError(response.message);
      }
    } catch (err) {
      setError(err.message || 'Password change failed. Please try again.');
      console.error('Error during password change:', err.message || err);
    } finally {
      setLoading(false);
    }
  };

  const handleSuccessDialogClose = () => {
    setIsSuccessDialogOpen(false);
    onPasswordChangeSuccess();
    onClose();
  };

  return (
    <>
      <CustomDialog
        open={open}
        onClose={onClose}
        title="Change Password"
        onSubmit={handlePasswordChange}
        submitLabel="Change Password"
        submitLoading={loading}
        submitDisabled={loading}
      >
        <TextField
          label="Old Password"
          type={showPassword ? 'text' : 'password'}
          fullWidth
          variant="outlined"
          margin="normal"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
            sx: {
              fontFamily: 'Hind Madurai',
              color: '#581a7d',
            },
          }}
          InputLabelProps={{
            sx: {
              fontFamily: 'Hind Madurai',
              color: '#581a7d',
              '&.Mui-focused': {
                color: '#581a7d',
              },
            },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#581a7d',
              },
              '&:hover fieldset': {
                borderColor: '#581a7d',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#581a7d',
              },
            },
          }}
        />
        <TextField
          label="New Password"
          type={showPassword ? 'text' : 'password'}
          fullWidth
          variant="outlined"
          margin="normal"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
            sx: {
              fontFamily: 'Hind Madurai',
              color: '#581a7d',
            },
          }}
          InputLabelProps={{
            sx: {
              fontFamily: 'Hind Madurai',
              color: '#581a7d',
              '&.Mui-focused': {
                color: '#581a7d',
              },
            },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#581a7d',
              },
              '&:hover fieldset': {
                borderColor: '#581a7d',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#581a7d',
              },
            },
          }}
        />
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </CustomDialog>

      <CustomDialog
        open={isSuccessDialogOpen}
        onClose={handleSuccessDialogClose}
        title="Successful"
        onSubmit={handleSuccessDialogClose}
        submitLabel="Ok"
      >
        <p>Password changed successfully!</p>
      </CustomDialog>
    </>
  );
}

export default PasswordChangeDialog;
