import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, CircularProgress, Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { styled } from '@mui/system';
import { getOrderList } from '../../services/repositories/OrderListService';
// import { postSubmitOrder } from '../../services/repositories/SubmitOrderService'; 
import ParallaxHeader from '../parallaxheader/ParallaxHeader'; 
import topBanner from '../../assets/images/topbanner.jpg';
import Confetti from 'react-confetti';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff',
  minHeight: '100vh',
}));

const StyledTable = styled(Table)(({ theme }) => ({
  fontFamily: 'Hind Madurai, sans-serif',
  '& th': {
    fontWeight: 'bold',
    fontFamily: 'Hind Madurai, sans-serif',
    color: '#581a7d',
    textAlign: 'left',
  },
  '& td': {
    verticalAlign: 'middle',
    height: '60px',
  },
  '& th.status-header': {
    textAlign: 'center',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '20px',
  backgroundColor: '#581a7d',
  color: '#fff',
  fontFamily: 'Hind Madurai, sans-serif',
  '&:hover': {
    backgroundColor: '#260b36',
  },
}));

const TableWrapper = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(4),
}));

function Records() {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submittingOrder, setSubmittingOrder] = useState(null);
  const [isFailedDialogOpen, setIsFailedDialogOpen] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false); 
  // const [errorTitle, setErrorTitle] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [confetti, setConfetti] = useState(false);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const data = await getOrderList(navigate);
        setOrders(data.data);
      } catch (error) {
        console.error('Error fetching order list:', error);
        setError('Failed to fetch order list.');
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [navigate]);

  const handleSubmit = async () => {
    // const orderToSubmit = orders.find(order => order.status === 0);
    // if (!orderToSubmit) return;
    // setSubmittingOrder(orderToSubmit.id);
    try {
      // const result = await postSubmitOrder(navigate);
      // if (result.code === 2000) {
      //   setOrders(prevOrders => prevOrders.map(order =>
      //     order.id === orderToSubmit.id ? { ...order, status: 1 } : order
      //   ));
      //   setConfetti(true); 
      //   setSuccessDialogOpen(true);
      // } else if (result.code === 1010) {
      //   setErrorTitle('You are so lucky!');
      //   setErrorMessage(result.message || 'An error occurred');
      //   setIsFailedDialogOpen(true);
      //   setConfetti(true); 
      // } else {
      //   setErrorTitle('You are so lucky!');
      //   setErrorMessage(result.message || 'An error occurred');
      //   setIsFailedDialogOpen(true);
      // }

      navigate('/booking-detail');
    } catch (error) {
      console.error('Error submitting order:', error);
      setErrorMessage('Failed to submit order.');
      setIsFailedDialogOpen(true);
    } finally {
      setSubmittingOrder(null);
    }
  };

  const handleFailedDialogClose = () => {
    setIsFailedDialogOpen(false);
    setConfetti(false);
  };

  const handleSuccessDialogClose = () => {
    setSuccessDialogOpen(false);
    setConfetti(false); 
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center', 
          justifyContent: 'center',
          minHeight: '100vh',
          width: '100%', 
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <StyledBox>
      <ParallaxHeader title="Records" backgroundImage={topBanner} />
      <Container sx={{ mt: 4 }}>
        {error ? (
          <Typography color="error" sx={{ fontFamily: 'Hind Madurai, sans-serif' }}>{error}</Typography>
        ) : (
          <TableWrapper>
            <TableContainer component={Paper}>
              <StyledTable>
                <TableHead>
                  <TableRow>
                    <TableCell>Order Code</TableCell>
                    <TableCell>Product</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Voucher</TableCell>
                    <TableCell className="status-header">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orders.map((order) => (
                    <TableRow key={order.id}>
                      <TableCell>{order.orderCode}</TableCell>
                      <TableCell>{order.product.title}</TableCell>
                      <TableCell>₹{order.productAmt}</TableCell>
                      <TableCell>₹{order.productComm}</TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        {order.status === 0 ? (
                          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <StyledButton onClick={handleSubmit} disabled={submittingOrder === order.id}>
                              {submittingOrder === order.id ? <CircularProgress size={24} /> : 'Submit'}
                            </StyledButton>
                          </Box>
                        ) : (
                          'Completed'
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </StyledTable>
            </TableContainer>
          </TableWrapper>
        )}
      </Container>
      {confetti && <Confetti />}

      <Dialog
        open={isFailedDialogOpen}
        onClose={handleFailedDialogClose}
      >
        {/* <DialogTitle>{errorTitle}</DialogTitle> */}
        <DialogTitle>Sorry</DialogTitle>
        <DialogContent>
          <Typography>{errorMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFailedDialogClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={successDialogOpen}
        onClose={handleSuccessDialogClose}
      >
        <DialogTitle>Success</DialogTitle>
        <DialogContent>
          <Typography>Order submitted successfully!</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSuccessDialogClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </StyledBox>
  );
}

export default Records;
