import React, { useState, useEffect } from 'react';
import { Box, Container, Grid, Typography, Divider, CircularProgress, TextField, Button } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import ParallaxHeader from '../parallaxheader/ParallaxHeader'; 
import topBanner from '../../assets/images/topbanner.jpg';
import CustomDialog from '../customdialog/CustomDialog';
import { getProfile } from '../../services/repositories/ProfileService';
import { postAddBank } from '../../services/repositories/AddBankService';

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#581a7d',
    },
    '&:hover fieldset': {
      borderColor: '#581a7d',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#581a7d',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#581a7d',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#581a7d',
  },
  '& .MuiInputBase-input': {
    fontFamily: 'Hind Madurai',
    color: '#581a7d',
    textAlign: 'right',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '20px',
  border: `1px solid #581a7d`,
  color: '#581a7d',
  fontFamily: 'Hind Madurai, sans-serif',
  padding: theme.spacing(1, 2),
  margin: theme.spacing(0.5),
  '&:hover': {
    backgroundColor: 'rgba(82, 7, 12, 0.1)',
  },
}));

function AddBank() {
  const [pin, setPin] = useState('');
  const [bankOwnerName, setBankOwnerName] = useState('');
  const [bankName, setBankName] = useState('');
  const [bankOwnerPhone, setBankOwnerPhone] = useState('');
  const [bankOwnerCardNo, setBankOwnerCardNo] = useState('');
  // const [bankAddress, setBankAddress] = useState('');
  const [bankIFSC, setBankIFSC] = useState('');
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const data = await getProfile(navigate);
        setProfile(data.data);
        setBankOwnerName(data.data.customer.bankOwnerName);
        setBankName(data.data.customer.bankName);
        setBankOwnerPhone(data.data.customer.bankOwnerPhone);
        setBankOwnerCardNo(data.data.customer.bankOwnerCardNo);
        setBankIFSC(data.data.customer.bankIfsc);
      
      } catch (error) {
        console.error('Error fetching profile:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [navigate]);

  const handlePinChange = (event) => {
    const value = event.target.value;
    const regex = /^\d{0,6}$/;
    if (regex.test(value)) {
      setPin(value);
    }
  };

  const handleBankOwnerNameChange = (event) => {
    const value = event.target.value;
    const regex = /^[A-Za-z\s]*$/; 
    if (regex.test(value)) {
      setBankOwnerName(value);
    }
  };

  const handleBankOwnerPhoneNoChange = (event) => {
    const value = event.target.value;
    const regex = /^\d*$/;
    if (regex.test(value)) {
        setBankOwnerPhone(value);
    }
  };

  const handleBankOwnerCardNoChange = (event) => {
    const value = event.target.value;
    const regex = /^[a-zA-Z0-9@._-]*$/;  
    if (regex.test(value)) {
      setBankOwnerCardNo(value);
    }
};

  const handleAddBank = async () => {
    if (!pin || !bankOwnerName || !bankName || !bankOwnerPhone || !bankOwnerCardNo || !bankIFSC) {
      setDialogContent('All fields must be filled.');
      setIsSuccess(false);
      setIsDialogOpen(true);
      return;
    }

    try {
      const response = await postAddBank({
        pin,
        bank_owner_name: bankOwnerName,
        bank_name: bankName,
        bank_owner_phone: bankOwnerPhone,
        bank_owner_card_no: bankOwnerCardNo,
        bank_ifsc: bankIFSC,
        navigate: navigate
      });

      if (response.code === '2000' || response.code === 2000) {
        setDialogContent('Bank details updated successfully.');
        setIsSuccess(true);
        setIsDialogOpen(true);

        const timeoutId = setTimeout(() => {
          setIsDialogOpen(false);
          navigate('/withdrawal');
        }, 2000);

        return () => clearTimeout(timeoutId);
      } else {
        setDialogContent(response.message || 'Failed to add bank details.');
        setIsSuccess(false);
        setIsDialogOpen(true);
      }
    } catch (error) {
      setDialogContent('An error occurred. Please try again.');
      setIsSuccess(false);
      setIsDialogOpen(true);
    } 
    // finally {
    //   setIsDialogOpen(true);
    // }
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          width: '100%', 
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!profile) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh', 
        }}
      >
        <Typography variant="h6">No Data Available</Typography>
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <ParallaxHeader title="Bank Details" backgroundImage={topBanner} />
      
      <Container sx={{ flex: 1, mt: 4, position: 'relative', display: 'flex', flexDirection: 'column' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <StyledTextField
              label="Withdraw Password"
              value={pin}
              fullWidth
              variant="outlined"
              onChange={handlePinChange}
              placeholder="Enter Pin"
              inputProps={{ maxLength: 6 }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledTextField
              label="Bank Owner Name"
              value={bankOwnerName}
              fullWidth
              variant="outlined"
              onChange={handleBankOwnerNameChange}
              placeholder="Enter Bank Owner Name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledTextField
              label="Bank Name/UPI (Enter 'UPI' if withdraw via UPI)"
              value={bankName}
              fullWidth
              variant="outlined"
              onChange={(e) => setBankName(e.target.value)}
              placeholder="Enter Bank Name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledTextField
              label="Phone Number"
              value={bankOwnerPhone}
              fullWidth
              variant="outlined"
              onChange={handleBankOwnerPhoneNoChange}
              placeholder="Enter Phone Number"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledTextField
              label="Bank Account Number/UPI ID"
              value={bankOwnerCardNo}
              fullWidth
              variant="outlined"
              onChange={handleBankOwnerCardNoChange}
              placeholder="Enter Account No"
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <StyledTextField
              label="Bank Address"
              value={bankAddress}
              fullWidth
              variant="outlined"
              onChange={(e) => setBankAddress(e.target.value)}
              placeholder="Enter Bank Address"
            />
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <StyledTextField
              label="Bank IFSC (Enter 'UPI' if withdraw via UPI)"
              value={bankIFSC}
              fullWidth
              variant="outlined"
              onChange={(e) => setBankIFSC(e.target.value)}
              placeholder="Enter Bank IFSC"
            />
          </Grid>
        </Grid>

        <Box sx={{ flexGrow: 1 }} /> 

        <Divider sx={{ my: 4 }} />

        <Box sx={{ padding: '16px', display: 'flex', justifyContent: 'center', mb: 4 }}>
          <StyledButton
            variant="contained"
            color="primary"
            sx={{ borderRadius: '20px', backgroundColor: '#581a7d', color: '#fff' }}
            onClick={handleAddBank}
          >
            Add/Edit Bank
          </StyledButton>
        </Box>

        <CustomDialog
          open={isDialogOpen}
          onClose={handleDialogClose}
          title={isSuccess ? 'Success' : 'Sorry'}
          onSubmit={handleDialogClose}
          submitLabel="OK"
        >
          <Typography>{dialogContent}</Typography>
        </CustomDialog>
      </Container>
    </Box>
  );
}

export default AddBank;
