class RegisterModel {
    constructor({ code = '', message = '', data = {} } = {}) {
      this.code = code;
      this.message = message;
      this.data = data ? new RegisterDataModel(data) : {};
    }
  }
  
  class RegisterDataModel {
    constructor({ 
      name = [], 
      email = [], 
      password = [], 
      phone = [], 
      referral_code = [] 
    } = {}) {
      this.name = name;
      this.email = email;
      this.password = password;
      this.phone = phone;
      this.referralCode = referral_code;
    }
  }
  
  export default RegisterModel;