import React, { useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ArticleCarousel.css';

// import article1 from '../../assets/images/mid5/mid51.jpg';
import article2 from '../../assets/images/mid5/mid52.webp';
import article3 from '../../assets/images/mid5/mid53.webp';
import article4 from '../../assets/images/mid5/mid54.webp';
import article5 from '../../assets/images/mid5/mid55.webp';
import article6 from '../../assets/images/mid5/mid56.webp';

const articles = [
  { src: article6, title: 'Kapish Jewellery Scam Alert: Never Provide Your Payment Details Online', description: 'Stay informed with our latest alert regarding online scams targeting Kapish Jewellery customers. This article highlights the importance of never sharing your payment details online and provides tips to protect yourself from fraud. Learn how to identify potential scams and ensure your jewellery purchases are safe.' },
  // { src: article1, title: 'Reveal Your Destiny: 6th July Lucky Draw', description: 'On July 6th, the captivating Lucky Draw event, hosted by Emcee Anand K from Mediacorp in collaboration with Oli 968, turned an ordinary day into a jubilant celebration filled with luck and happiness.' },
  { src: article2, title: 'Timeless Gifts for Dad: The Elegance of Gold Watches in India’s Fashion Scene', description: 'The allure of gold watches lies in their ability to blend seamlessly with both casual and formal attire, making them a versatile and cherished accessory. This article delves into the reasons behind the enduring popularity of gold watches in India, offering insights into the best brands, styles, and tips for selecting the perfect watch for your dad.' },
  { src: article3, title: 'Father’s Day Special: The Journey of Men’s Gold Jewellery from India to Singapore', description: "Explore the cultural tapestry and craftsmanship that define men's gold jewellery, tracing its journey from the intricate workshops of India to the stylish showcases of India. Discover the rich heritage and contemporary allure that make these pieces timeless symbols of elegance and tradition." },
  { src: article4, title: 'Urban and Blooms: A Jewel in the Crown of India’s 59th Celebration', description: "Celebrate India's 59th birthday with 'Urban and Blooms,' a captivating jewellery collection by Kapish Jewellery. Explore designs inspired by India's unique heritage and let these exquisite jewels adorn your special moments this National Day. Discover the stories behind each piece and celebrate the vibrant tapestry of India's journey." },
  { src: article5, title: 'Celebrating Heritage and Roots: Kapish Jewellery’s 2024 Lookbook', description: 'Discover "Heritage and Roots," a stunning collection from Kapish Jewellery. Each piece is a tribute to tradition and innovation, perfect for those who appreciate cultural diversity and modern style.' },
];

const PrevArrow = ({ onClick }) => (
  <div
    onClick={onClick}
    style={{
      position: 'absolute',
      top: '50%',
      left: '10px',
      transform: 'translateY(-50%)',
      zIndex: 2,
      cursor: 'pointer',
      background: 'rgba(88, 26, 125, 0.5)',
      color: 'white',
      borderRadius: '0',
      padding: '10px',
      fontSize: '24px',
      width: '40px',
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    &#9664;
  </div>
);

const NextArrow = ({ onClick }) => (
  <div
    onClick={onClick}
    style={{
      position: 'absolute',
      top: '50%',
      right: '10px',
      transform: 'translateY(-50%)',
      zIndex: 2,
      cursor: 'pointer',
      background: 'rgba(88, 26, 125, 0.5)',
      color: 'white',
      borderRadius: '0',
      padding: '10px',
      fontSize: '24px',
      width: '40px',
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    &#9654;
  </div>
);

const ArticleCarousel = () => {
  const navigate = useNavigate();
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    centerMode: true,
    centerPadding: '0',
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Box sx={{ width: '100%', position: 'relative', }}>
      <Slider ref={sliderRef} {...settings}>
        {articles.map((article, index) => (
          <Box
            onClick={()=>index===0&&(navigate('/scam-alert'))}
            key={index}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: '10px',
              boxSizing: 'border-box',
              height: 'auto',
            }}
          >
            <img
              src={article.src}
              alt={`Article ${index + 1}`}
              style={{ width: '100%', height: 'auto', display: 'block', objectFit: 'cover' }}
            />
            <Box sx={{ marginTop: '10px', width: '100%' }}>
              <Typography
                variant="h1"
                sx={{ fontSize: '24px', marginBottom: '10px', textAlign: 'left' }}
              >
                {article.title}
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontFamily: 'Hind Madurai, sans-serif', fontSize: '16px', textAlign: 'left'  }}
              >
                {article.description}
              </Typography>
            </Box>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default ArticleCarousel;
