import React from 'react';
import { Box, Typography, Grid,List, ListItem, ListItemText, ListItemIcon} from '@mui/material';
import { Link } from 'react-router-dom';
import scamImage from '../../assets/images/scam/scam.png';
import CircleIcon from '@mui/icons-material/Circle';


const ScamAlert = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        paddingTop: '64px',
        boxSizing: 'border-box',
        overflowX: 'hidden', 
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'stretch',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box
          sx={{
            width: { xs: '100%', md: '50%' },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={scamImage}
            alt="Scam Alert"
            style={{ width: '100%', height: 'auto' }}
          />
        </Box>

        <Box
          sx={{
            width: { xs: '100%', md: '50%' },
            backgroundColor: '#581a7d',
            padding: { xs: '20px', md: '60px' },
            color: 'white',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant="body2" sx={{ fontSize: '14px', marginBottom: '30px', textAlign: 'left' }}>
            ARTICLE
          </Typography>
          <Typography
            variant="h1"
            sx={{
              color: '#ffffff',
              fontSize: '2.5rem',
              margin: 0,
              paddingBottom: '50px',
              fontFamily: 'Hind Madurai, sans-serif',
              textAlign: 'left',
            }}
          >
            Kapish Jewellery Scam Alert: Never Provide Your Payment Details Online
          </Typography>
          <Typography variant="body2" sx={{ fontSize: '16px', marginBottom: '10px', textAlign: 'left', fontFamily: 'Hind Madurai, sans-serif', fontWeight: 500 }}>
            Stay informed with our latest alert regarding online scams targeting Kapish Jewellery customers.
            This article highlights the importance of never sharing your payment details online and provides
            tips to protect yourself from fraud. Learn how to identify potential scams and ensure your jewellery
            purchases are safe.
          </Typography>
          <Typography variant="body2" sx={{ fontSize: '16px', marginTop: '20px', textAlign: 'left', fontFamily: 'Hind Madurai, sans-serif', fontWeight: 500 }}>
            Published On: 4 October, 2024 | Categories: News
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'left',
          minHeight: '300px',
          padding: { xs: '0 20px', md: '0 100px', lg: '0 200px' },
          boxSizing: 'border-box',
          flexDirection: 'column',
        }}
      >
        <Typography
          variant="body1"
          sx={{
            fontSize: '20px',
            fontFamily: 'Hind Madurai, sans-serif',
            color: '#5f6368',
            fontWeight: 400,
            marginBottom: '20px',
            textAlign: 'left',
          }}
        >
          At Kapish Jewellery, your trust and security are our top priorities. We are committed to ensuring that your shopping
          experience with us is safe and secure. Unfortunately, we have recently been made aware of a scam circulating online
          where fraudulent individuals pose as representatives of Kapish Jewellery, asking for payment details from customers.
          <br /><br />
          We want to clarify that Kapish Jewellery will never ask for your payment details online via email, social media, or any
          other unverified source.
        </Typography>
      </Box>

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'left',
          textAlign: 'left',
          minHeight: '300px',
          padding: { xs: '50px 20px', md: '50px 200px', lg: '50px 300px' },
          boxSizing: 'border-box',
          flexDirection: 'column',
          backgroundColor: '#ffffff',
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: '2.25rem',
            margin: 0,
            paddingBottom: '20px',
            fontFamily: 'Hind Madurai, sans-serif',
            textAlign: 'left',
          }}
        >
          How The Scam Works
        </Typography>

        <Typography
          variant="body1"
          sx={{
            fontSize: '20px',
            fontFamily: 'Hind Madurai, sans-serif',
            color: '#5f6368',
            fontWeight: 400,
            marginBottom: '20px',
            textAlign: 'left',
          }}
        >
          The scammers may reach out to you through social media platforms, emails, or fake websites, claiming to represent Kapish Jewellery. They may request personal information such as your credit card number, bank account details, or other payment information in exchange for exclusive offers, discounts, or prize claims.
          <br /><br />
          These requests are not legitimate and have no connection to Kapish Jewellery. We strongly advise against sharing any payment details or personal information with such sources.
        </Typography>

        <Typography
          variant="h1"
          sx={{
            fontSize: '2.25rem',
            margin: 0,
            paddingTop: '50px',
            paddingBottom: '20px',
            fontFamily: 'Hind Madurai, sans-serif',
            textAlign: 'left',
          }}
        >
          How to Identify a Scam
        </Typography>

        <Typography
          variant="body1"
          sx={{
            fontSize: '20px',
            fontFamily: 'Hind Madurai, sans-serif',
            color: '#5f6368',
            fontWeight: 400,
            marginBottom: '20px',
            textAlign: 'left',
          }}
        >
          Here are some tips to help you recognise these scams and protect yourself:
        </Typography>

        <Grid sx={{ width: '100%' }}>
          <Grid container item xs={12} sx={{ marginBottom: '20px', alignItems: 'flex-start' }}>
            <Grid item xs={12} sm={2}>
              <Typography
                variant="body1"
                sx={{
                  fontSize: { xs: '18px', sm: '20px' }, 
                  fontFamily: 'Hind Madurai, sans-serif',
                  color: '#5f6368',
                  fontWeight: 400,
                  marginBottom: { xs: '10px', sm: '0' } 
                }}
              >
                Unsolicited<br />Messages
              </Typography>
            </Grid>
            <Grid item xs={12} sm={10}>
              <Typography
                variant="body1"
                sx={{
                  fontSize: { xs: '16px', sm: '20px' }, 
                  fontFamily: 'Hind Madurai, sans-serif',
                  color: '#5f6368',
                  fontWeight: 400,
                }}
              >
                If you receive a message claiming to be from Kapish Jewellery asking for payment details, but you have not initiated a purchase or inquiry, it is likely a scam.
              </Typography>
            </Grid>
          </Grid>

          <Grid container item xs={12} sx={{ marginBottom: '20px', alignItems: 'flex-start' }}>
            <Grid item xs={12} sm={2}>
              <Typography
                variant="body1"
                sx={{
                  fontSize: { xs: '18px', sm: '20px' },
                  fontFamily: 'Hind Madurai, sans-serif',
                  color: '#5f6368',
                  fontWeight: 400,
                  marginBottom: { xs: '10px', sm: '0' }
                }}
              >
                Payment<br />Requests
              </Typography>
            </Grid>
            <Grid item xs={12} sm={10}>
              <Typography
                variant="body1"
                sx={{
                  fontSize: { xs: '16px', sm: '20px' },
                  fontFamily: 'Hind Madurai, sans-serif',
                  color: '#5f6368',
                  fontWeight: 400,
                }}
              >
                Kapish Jewellery will never ask for payment details over email, direct messages, or social media platforms. Any payments for purchases should only be made through our official website or in-store at authorised Kapish Jewellery locations.
              </Typography>
            </Grid>
          </Grid>

          <Grid container item xs={12} sx={{ marginBottom: '20px', alignItems: 'flex-start' }}>
            <Grid item xs={12} sm={2}>
              <Typography
                variant="body1"
                sx={{
                  fontSize: { xs: '18px', sm: '20px' },
                  fontFamily: 'Hind Madurai, sans-serif',
                  color: '#5f6368',
                  fontWeight: 400,
                  marginBottom: { xs: '10px', sm: '0' }
                }}
              >
                Suspicious<br />Links
              </Typography>
            </Grid>
            <Grid item xs={12} sm={10}>
              <Typography
                variant="body1"
                sx={{
                  fontSize: { xs: '16px', sm: '20px' },
                  fontFamily: 'Hind Madurai, sans-serif',
                  color: '#5f6368',
                  fontWeight: 400,
                }}
              >
                Be cautious of links in unsolicited messages. Always verify the authenticity of a website before providing any personal information or making a payment.
              </Typography>
            </Grid>
          </Grid>

          <Grid container item xs={12} sx={{ marginBottom: '20px', alignItems: 'flex-start' }}>
            <Grid item xs={12} sm={2}>
              <Typography
                variant="body1"
                sx={{
                  fontSize: { xs: '18px', sm: '20px' },
                  fontFamily: 'Hind Madurai, sans-serif',
                  color: '#5f6368',
                  fontWeight: 400,
                  marginBottom: { xs: '10px', sm: '0' }
                }}
              >
                Impersonation<br />Accounts
              </Typography>
            </Grid>
            <Grid item xs={12} sm={10}>
              <Typography
                variant="body1"
                sx={{
                  fontSize: { xs: '16px', sm: '20px' },
                  fontFamily: 'Hind Madurai, sans-serif',
                  color: '#5f6368',
                  fontWeight: 400,
                }}
              >
                Scammers often create fake social media accounts that resemble Kapish Jewellery. Always confirm the account’s authenticity by checking for verification badges or directly visiting our official social media profiles.
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Typography
          variant="h1"
          sx={{
            fontSize: '2.25rem',
            margin: 0,
            paddingTop: '50px',
            paddingBottom: '20px',
            fontFamily: 'Hind Madurai, sans-serif',
            textAlign: 'left',
          }}
        >
          What to Do If You Encounter a Scam 
        </Typography>

        <Typography
            variant="body1"
            sx={{
                fontSize: '20px',
                fontFamily: 'Hind Madurai, sans-serif',
                color: '#5f6368',
                fontWeight: 400,
                marginBottom: '20px',
                textAlign: 'left',
            }}
            >
            If you believe you have been targeted by a scam pretending to be Kapish Jewellery, here’s what you should do:
            </Typography>

            <List sx={{ marginBottom: '20px' }}>
            <ListItem>
                <ListItemIcon sx={{ color: '#5f6368' }}>
                    <CircleIcon sx={{ fontSize: '10px' }} />
                </ListItemIcon>
                <ListItemText
                    primary="Do not provide any personal or payment information."
                    primaryTypographyProps={{
                        sx: {
                            fontSize: '20px',
                            fontFamily: 'Hind Madurai, sans-serif',
                            color: '#5f6368',
                            fontWeight: 400,
                        },
                    }}
                />
            </ListItem>
            <ListItem>
                <ListItemIcon sx={{ color: '#5f6368' }}>
                    <CircleIcon sx={{ fontSize: '10px' }} />
                </ListItemIcon>
                <ListItemText
                    primary="Report the scam to us immediately via our official contact channels."
                    primaryTypographyProps={{
                        sx: {
                            fontSize: '20px',
                            fontFamily: 'Hind Madurai, sans-serif',
                            color: '#5f6368',
                            fontWeight: 400,
                        },
                    }}
                />
            </ListItem>
            <ListItem>
                <ListItemIcon sx={{ color: '#5f6368' }}>
                    <CircleIcon sx={{ fontSize: '10px' }} />
                </ListItemIcon>
                <ListItemText
                    primary="Block and report any social media profiles, emails, or websites that seem suspicious or fraudulent."
                    primaryTypographyProps={{
                        sx: {
                            fontSize: '20px',
                            fontFamily: 'Hind Madurai, sans-serif',
                            color: '#5f6368',
                            fontWeight: 400,
                        },
                    }}
                />
            </ListItem>
            <ListItem>
                <ListItemIcon sx={{ color: '#5f6368' }}>
                    <CircleIcon sx={{ fontSize: '10px' }} />
                </ListItemIcon>
                <ListItemText
                    primary="If you have shared your payment details, contact your bank or credit card provider immediately to prevent unauthorized transactions."
                    primaryTypographyProps={{
                        sx: {
                            fontSize: '20px',
                            fontFamily: 'Hind Madurai, sans-serif',
                            color: '#5f6368',
                            fontWeight: 400,
                        },
                    }}
                />
            </ListItem>
        </List>

        <Typography
            variant="h1"
            sx={{
                fontSize: '2.25rem',
                margin: 0,
                paddingTop: '50px',
                paddingBottom: '20px',
                fontFamily: 'Hind Madurai, sans-serif',
                textAlign: 'left',
            }}
        >
            Stay Safe and Shop Securely
        </Typography>
        <Typography
            variant="body1"
            sx={{
                fontSize: '20px',
                fontFamily: 'Hind Madurai, sans-serif',
                color: '#5f6368',
                fontWeight: 400,
                marginBottom: '20px',
                textAlign: 'left',
            }}
        >
            At Kapish Jewellery, we value your safety. Remember, all communication regarding purchases, orders, or customer service will be conducted through our official website or verified customer service channels. We will never ask for your payment details through unverified platforms.
            <br /><br />
            To stay updated on any future advisories or security tips, please follow our official social media pages.
        </Typography>

        <Typography
            variant="h1"
            sx={{
                fontSize: '2.25rem',
                margin: 0,
                paddingTop: '50px',
                paddingBottom: '20px',
                fontFamily: 'Hind Madurai, sans-serif',
                textAlign: 'left',
            }}
        >
            Contact Us
        </Typography>
        <Typography
            variant="body1"
            sx={{
                fontSize: '20px',
                fontFamily: 'Hind Madurai, sans-serif',
                color: '#5f6368',
                fontWeight: 400,
                marginBottom: '20px',
                textAlign: 'left',
            }}
        >
            If you have any concerns or would like to verify any communications you’ve received, please do not hesitate to contact our customer service team directly through our official contact page:  
            <Link to="/customer-service" style={{ color: '#5f6368', textDecoration: 'underline' }}>
                Contact Kapish Jewellery
            </Link>.
            <br /><br />
            Stay vigilant, and thank you for trusting Kapish Jewellery for your gold and jewellery needs.
        </Typography>
      </Box>
    </Box>
  );
};

export default ScamAlert;
