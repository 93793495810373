import axios from 'axios';
import { API_ENDPOINTS } from '../../config/ApiConfig';
import GeneralModel from '../models/GeneralModel';

export const postLogout = async () => {
  try {
    const response = await axios.post(API_ENDPOINTS.postLogout, {}, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      }
    });

    const data = response.data;

    return new GeneralModel(data);
  } catch (error) {
    if (error.response) {
      const data = error.response.data;
      console.error(`API Error: (${data.status}) ${data.code} - ${data.message}`);
      return new GeneralModel(data);
    } else {
      console.error('Error during logout:', error.message || error);
    }
    throw error;
  }
};
