import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import './Mid2.css';
import m1 from '../../assets/images/mid2/1.jpg'; 
import m2 from '../../assets/images/mid2/2.jpg'; 
import m3 from '../../assets/images/mid2/3.jpg'; 

const Mid2 = () => {
  return (
    <Box
      sx={{
        padding: '20px',
        backgroundColor: '#fff',
        textAlign: 'center',
        mr: { xs: 2, sm: 15, md: 20, lg: 30, xl: 36 },
        ml: { xs: 2, sm: 15, md: 20, lg: 30, xl: 36 },
      }}
    >
      <Typography
        variant="h2"
        sx={{
          paddingBottom: '10px',
          fontSize: '27px',
        }}
      >
        PUBLIC EYE
      </Typography>

      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={4}>
          <img src={m1} alt="" style={{ width: '100%', borderRadius: '8px' }} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <img src={m2} alt="" style={{ width: '100%', borderRadius: '8px' }} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <img src={m3} alt="" style={{ width: '100%', borderRadius: '8px' }} />
        </Grid>
      </Grid>

      <Box sx={{ marginTop: '20px' }}>
        <img
          src="https://jewelsbucket.com/Uploads/ImgGoldenDreams.jpg6Pro_Type.jpeg"
          alt=""
          style={{ width: '100%', borderRadius: '8px' }}
        />
      </Box>

      <Box sx={{ marginTop: '20px' }}>
        <img
          src="https://jewelsbucket.com/Uploads/Imgdiamonddreams3.jpg5Pro_Type.jpeg"
          alt=""
          style={{ width: '100%', borderRadius: '8px' }}
        />
      </Box>
    </Box>
  );
};

export default Mid2;
