import React, { useState } from "react";
import { Box, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import banner from '../../assets/images/promotions/topbanner.png';
import PopUpDialog from '../../components/popupdialog/PopUpDialog'; 
import { useNavigate } from 'react-router-dom'; 

const theme = createTheme({
  typography: {
    fontFamily: 'Hind Madurai, sans-serif',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#581a7d',
          color: 'white',
          width: '120px',
          height: '40px',
          borderRadius: '0',
          fontSize: '17px',
          '&:hover': {
            backgroundColor: '#260b36',
          },
        },
      },
    },
  },
});

function ExploreButton({ onClick }) {
  return (
    <ThemeProvider theme={theme}>
      <Button variant="contained" onClick={onClick}>
        JOIN NOW
      </Button>
    </ThemeProvider>
  );
}

const PromotionTopbanner = () => {
  const navigate = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoginReminderOpen, setIsLoginReminderOpen] = useState(false);

  const handleOpenDialog = () => {
    if (localStorage.getItem('accessToken')) {
      navigate('/booking');
    } else {
      setIsLoginReminderOpen(true);
    }
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleCloseLoginReminder = () => {
    setIsLoginReminderOpen(false);
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: { xs: '50vh', lg: '50vh' },
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <img
        src={banner}
        alt="Top Banner"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: -1,
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: { xs: '20%', lg: '50%' },
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
          color: '#fff',
          width: '100%',
          paddingLeft: { xs: '20px', lg: '0px' },
          paddingRight: { xs: '20px', lg: '0px' },
        }}
      >
        <Typography
          variant="h1"
          sx={{
            color: '#581a7d',
            fontSize: { xs: '2rem', lg: '3rem' },
            margin: 0,
            paddingBottom: '30px',
            paddingTop: { xs: '350px', lg: '100px' },
          }}
        >
          Are You Ready to Join the Journey?
        </Typography>

        <Typography
            variant="h2"
            sx={{
                color: '#5f6368',
                // fontSize: { xs: '1rem', lg: '1rem' },
                fontSize: '1rem',
                margin: 0,
                paddingBottom: '30px',
                paddingLeft: { xs: '20px', sm: '30px', md:'100px', lg: '250px', xl: '500px' },
                paddingRight: { xs: '20px', sm:'30px', md:'100px', lg: '250px', xl: '500px' },
                // whiteSpace: 'pre-line', 
                // textAlign: { xs: 'center', lg: 'center' }, 
                textAlign: 'center'
            }}
            >
            Engage with the timeless elegance of Kapish Jewels. Align yourself with a legacy of brilliance
            and radiance. Each curated promotion mirrors the sophistication and allure of our esteemed
            collections, offering participants the prospect of unique and exclusive accolades. We invite
            you to partake in this journey of refinement and distinction.
            </Typography>

        <ExploreButton onClick={handleOpenDialog} />
      </Box>

      <PopUpDialog open={isDialogOpen} onClose={handleCloseDialog} />

      <Dialog
        open={isLoginReminderOpen}
        onClose={handleCloseLoginReminder}
        aria-labelledby="login-reminder-dialog-title"
        aria-describedby="login-reminder-dialog-description"
      >
        <DialogTitle id="login-reminder-dialog-title">{"Please Log In"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="login-reminder-dialog-description">
            You need to be logged in to continue. Please log in to access this feature.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLoginReminder} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PromotionTopbanner;
