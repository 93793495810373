import axios from 'axios';
import { API_ENDPOINTS } from '../../config/ApiConfig';
import RegisterModel from '../models/RegisterModel';

export const postRegister = async ({ name, email, password, phone, referral_code }) => {
  try {
    const response = await axios.post(API_ENDPOINTS.postRegister, {
      name,
      email,
      password,
      phone,
      referral_code,
    });

    const data = response.data;

    return new RegisterModel(data);
  } catch (error) {
    if (error.response) {
      const data = error.response.data;
      console.error(`API Error: (${data.status}) ${data.code} - ${data.message}`);
      return new RegisterModel(data);
    } else {
      console.error('Error during registration:', error.message || error);
    }
    throw error;
  }
};
