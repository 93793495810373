class BankStatementModel {
  constructor({ code = '', data = [] } = {}, message = '') {
    this.code = code;
    this.message = message;
    this.data = Array.isArray(data) ? data.map(item => new BankStatementDataModel(item)) : [];
  }
}

class BankStatementDataModel {
  constructor({
    id = '',
    cust_id = '',
    transaction_type_id = '',
    action_type = '',
    credit = '0.00',
    debit = '0.00',
    balance = '0.00',
    created_by = '',
    withdrawal_id = '',
    created_at = '',
    updated_at = null,
    deleted_at = null,
    transaction_type = {},
  } = {}) {
    this.id = id;
    this.customerId = cust_id;
    this.transactionTypeId = transaction_type_id;
    this.actionType = action_type;
    this.credit = credit;
    this.debit = debit;
    this.balance = balance;
    this.createdBy = created_by;
    this.withdrawalId = withdrawal_id;
    this.createdAt = created_at;
    this.updatedAt = updated_at;
    this.deletedAt = deleted_at;
    this.transactionType = transaction_type ? new TransactionTypeModel(transaction_type) : {};
  }
}

class TransactionTypeModel {
  constructor({
    id = '',
    description_admin = '',
    description_customer = '',
    admin_selection = '',
    created_at = '',
    updated_at = '',
  } = {}) {
    this.id = id;
    this.descriptionAdmin = description_admin;
    this.descriptionCustomer = description_customer;
    this.adminSelection = admin_selection;
    this.createdAt = created_at;
    this.updatedAt = updated_at;
  }
}

export default BankStatementModel;
