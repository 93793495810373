class WithdrawalListModel {
  constructor({ code = '', data = [] } = {}, message = '') {
    this.code = code;
    this.message = message;
    this.data = Array.isArray(data) ? data.map(item => new WithdrawalListDataModel(item)) : [];
  }
}

class WithdrawalListDataModel {
  constructor({
    id = '',
    order_no = '',
    cust_id = '',
    rate = '',
    amount = '',
    bank_owner_name = '',
    bank_name = '',
    bank_owner_phone = '',
    bank_owner_card_no = '',
    bank_address = '',
    bank_ifsc = '',
    status = 0,
    updated_by = '',
    created_at = '',
    updated_at = '',
    amount_comm = '',
    amount_wallet = ''
  } = {}) {
    this.id = id;
    this.orderNo = order_no;
    this.customerId = cust_id;
    this.rate = rate;
    this.amount = amount;
    this.bankOwnerName = bank_owner_name;
    this.bankName = bank_name;
    this.bankOwnerPhone = bank_owner_phone;
    this.bankOwnerCardNo = bank_owner_card_no;
    this.bankAddress = bank_address;
    this.bankIfsc = bank_ifsc;
    this.status = status;
    this.updatedBy = updated_by;
    this.createdAt = created_at;
    this.updatedAt = updated_at;
    this.amountComm = amount_comm;
    this.amountWallet = amount_wallet;
  }
}

export default WithdrawalListModel;
