import React from 'react';
import { Box, Typography } from '@mui/material';
import m1 from '../../assets/images/mid1/1.jpg';
import m2 from '../../assets/images/mid1/2.jpg';
import m3 from '../../assets/images/mid1/3.jpg';
import m4 from '../../assets/images/mid1/4.jpg';
import m5 from '../../assets/images/mid1/5.jpg';
import m6 from '../../assets/images/mid1/6.jpg';
import m7 from '../../assets/images/mid1/7.jpg';

function CustomCard({ image, title, description }) {
  return (
    <Box
      sx={{
        width: '100%',
        border: '1px solid rgba(137, 115, 88, 0.2)',
        borderRadius: '12px',
        overflow: 'hidden',
        boxShadow: '0 1px 3px rgba(137, 115, 88, 0.3)',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box
        component="img"
        src={image}
        alt="card-image"
        sx={{
          width: '100%',
          height: 'auto',
          objectFit: 'cover',
        }}
      />
      <Box sx={{ padding: '10px' }}>
        <Typography
          variant="h6"
          sx={{
            color: '#333',
            marginBottom: '8px',
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontWeight: 100,
            color: '#666',
            lineHeight: '1.5',
          }}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );
}

function CustomSmallCard({ image, title }) {
  return (
    <Box
      sx={{
        width: '100%',
        border: '1px solid rgba(137, 115, 88, 0.2)',
        borderRadius: '12px',
        overflow: 'hidden',
        boxShadow: '0 1px 3px rgba(137, 115, 88, 0.3)',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box
        component="img"
        src={image}
        alt="card-image"
        sx={{
          width: 'auto',
          height: '90%',
          objectFit: 'cover',
        }}
      />
      <Box>
        <Typography
          variant="h7"
          sx={{
            color: '#777',
          }}
        >
          {title}
        </Typography>
      </Box>
    </Box>
  );
}

function Mid1() {
  return (
    <Box
      sx={{
        paddingTop: '100px',
        paddingBottom: '100px',
        textAlign: 'center',
        width: '100%',
        overflowX: 'hidden',
        boxSizing: 'border-box',
      }}
    >
      <Typography
        variant="h2"
        sx={{
          paddingBottom: '10px',
          fontSize: '27px',
        }}
      >
        BROWSE LATEST COLLECTIONS
      </Typography>

      <Typography variant="body">
        “A PIECE OF PERFECT JEWELLERY IS OFTEN A PIECE OF ART"
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          height: { md: '550px' },
          marginTop: '50px',
          mr: { xs: 2, sm: 15, md: 20, lg: 30, xl: 40 },
          ml: { xs: 2, sm: 15, md: 20, lg: 30, xl: 40 },
        }}
      >
        <Box
          sx={{
            flex: { xs: '1 1 100%', md: '0 0 30%' },
            height: { xs: 'auto', md: '100%' },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingRight: { md: '20px' },
            mb: { xs: 2, md: 0 },
          }}
        >
          <CustomCard
            image={m1}
            title="JEWELLERY"
            description="Adorable bracelets, necklaces, earrings, rings with unique embellishments from our exclusive closet. Grab up your gratifying piece of Jewellery."
          />
        </Box>

        <Box
          sx={{
            flex: { xs: '1 1 100%', md: '0 0 70%' },
            height: { xs: 'auto', md: '100%' },
            display: 'grid',
            gridTemplateRows: { xs: 'repeat(3, 1fr)', md: 'repeat(2, 1fr)' },
            gridTemplateColumns: { xs: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)' },
            gap: '16px',
          }}
        >
          <CustomSmallCard image={m2} title="RING" />
          <CustomSmallCard image={m3} title="BRACELET" />
          <CustomSmallCard image={m4} title="NECKLACE" />
          <CustomSmallCard image={m5} title="EARRINGS" />
          <CustomSmallCard image={m6} title="PENDANT" />
          <CustomSmallCard image={m7} title="TANMANIYA" />
        </Box>
      </Box>
    </Box>
  );
}

export default Mid1;
