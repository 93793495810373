import React, { useState, useEffect } from 'react';
import { Box, Container, Grid, Paper, Typography, CircularProgress, Button, Divider } from '@mui/material';
import { getBookingDetail } from '../../services/repositories/BookingDetailService';
import ParallaxHeader from '../parallaxheader/ParallaxHeader';
import topBanner from '../../assets/images/topbanner.jpg';
import { postSubmitOrder } from '../../services/repositories/SubmitOrderService';
import { TIMER_CONFIG } from '../../config/TimeConfig';
import { useNavigate } from 'react-router-dom';
import CustomDialog from '../customdialog/CustomDialog';
import { API_ENDPOINTS } from '../../config/ApiConfig';
import StarIcon from '@mui/icons-material/Star';
import Confetti from 'react-confetti';
import LuckyDrawModal from '../luckydrawmodal/LuckyDrawModal';

function BookingDetails() {
  const [bookingDetails, setBookingDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState({ title: '', message: '' });
  const [ratingDialogOpen, setRatingDialogOpen] = useState(false);
  const [ratingDialogContent, setRatingDialogContent] = useState({ title: '', message: '' });
  const [rating, setRating] = useState(5); 
  const [hover, setHover] = useState(-1);
  const [showConfetti, setShowConfetti] = useState(false);
  const [luckyDrawAmt, setLuckyDrawAmt] = useState(0);
  const [luckyDrawOpen, setLuckyDrawOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isRan, setIsRan] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchBookingDetails = async () => {
      try {
        const data = await getBookingDetail(navigate);
        setBookingDetails(data.data);
        setLuckyDrawAmt(data.data.luckyDrawAmount)
      } catch (error) {
        console.error('Error fetching booking details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBookingDetails();
  }, [navigate]);

  useEffect(() => {
    if(bookingDetails){
      if(bookingDetails.luckyDrawAmount){
        setLuckyDrawOpen(true);
        setShowConfetti(true);
      }
    }
  }, [bookingDetails]);

  const handleSubmitOrder = async () => {
    setIsDisabled(true);
    setLoading(true);
    try {
      if(!isRan){
        const response = await postSubmitOrder(navigate);
      
        setTimeout(() => {
          setLoading(false);

          if (response.code === 2000) { 
            setIsRan(true);
            setDialogContent({
              title: 'Congratulations!',
              message: 'Order submitted successfully!',
            });
            setDialogOpen(true);
            setShowConfetti(true);
    
            // const timeoutId = setTimeout(() => {
            //   setDialogOpen(false);
            //   setShowConfetti(false); 
            //   navigate('/booking');
            // }, 5000);
    
            // return () => clearTimeout(timeoutId);
          } else if(response.code === 1010) {
            setDialogContent({
              title: 'Congratulations! You are so lucky!',
              message: response.message || 'Order submission failed. Please try again later.',
            });
            setDialogOpen(true);
            setIsDisabled(false);
          } else {
            setDialogContent({
              title: 'Failed',
              message: response.message || 'Order submission failed. Please try again later.',
            });
            setDialogOpen(true);
            setIsDisabled(false);
          }
        }, TIMER_CONFIG.submitting);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error submitting order:', error);
      setDialogContent({
        title: 'Failed',
        message: 'Order submission failed. Please try again later.',
      });
      setDialogOpen(true);
      setIsDisabled(false);
    }
  };

  const handleRatingClick = (value) => {
    setRating(value);
    setRatingDialogContent({
      title: 'Thanks for rating us!',
      message: `You rated us ${value} star${value > 1 ? 's' : ''}.`,
    });
    setRatingDialogOpen(true);
    setShowConfetti(true); 
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center', 
          justifyContent: 'center',
          minHeight: '100vh',
          width: '100%', 
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  
  if (!bookingDetails) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Typography variant="h6">No Data Available</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', backgroundColor: '#fff', minHeight: '100vh' }}>
      {showConfetti && <Confetti />} 
      <ParallaxHeader title="Booking Details" backgroundImage={topBanner} />
      <Box sx={{ flexGrow: 1, padding: { xs: '16px', sm: '32px' } }}>
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Box sx={{ textAlign: 'center', mt: 4 }}>
                <Typography variant="h1" sx={{ fontWeight: 'bold', mb: 2, fontSize: '30px' }}>
                  {bookingDetails.product.title}
                </Typography>
                <Grid item xs={12}>
                  <Divider sx={{ my: 4, width: '100%' }} />
                </Grid>
                <img 
                  src={`${API_ENDPOINTS.displayImage}${bookingDetails.product.imgPath}`} 
                  alt={bookingDetails.product.title} 
                  style={{ maxWidth: '30vh', borderRadius: '10px' }}
                />
              </Box>
            </Grid>
  
            <Grid item xs={12}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <Paper elevation={3} sx={{ padding: '16px', backgroundColor: '#fff', borderRadius: '30px' }}>
                    <Typography variant="h6" sx={{ marginBottom: '8px' }}>
                      Price
                    </Typography>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px' }}>
                      ₹{bookingDetails.productAmt}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Paper elevation={3} sx={{ padding: '16px', backgroundColor: '#fff', borderRadius: '30px' }}>
                    <Typography variant="h6" sx={{ marginBottom: '8px' }}>
                      Voucher
                    </Typography>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px' }}>
                      ₹{bookingDetails.productComm}
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box sx={{ flexGrow: 1, padding: { xs: '15px 32px'} }}>
        <Grid container spacing={4} sx={{ justifyContent: 'center', mb: 4 }}>
          <Grid item xs={12} sm={4}>
            <Paper elevation={3} sx={{ padding: '16px', backgroundColor: '#fff', borderRadius: '30px', textAlign: 'center' }}>
              <Typography variant="h6">Rate Us:</Typography>
              <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
                {[1, 2, 3, 4, 5].map((value) => (
                  <StarIcon
                    key={value}
                    sx={{
                      cursor: 'pointer',
                      color: value <= (hover !== -1 ? hover : rating) ? '#581a7d' : '#C0C0C0',
                      fontSize: '30px',
                    }}
                    onClick={() => handleRatingClick(value)}
                    onMouseEnter={() => setHover(value)}
                    onMouseLeave={() => setHover(-1)}
                  />
                ))}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>

      <Grid item xs={12}>
        <Divider sx={{ my: 4, width: '100%' }} />
      </Grid>
      <Box sx={{ padding: '16px', display: 'flex', justifyContent: 'center', mb: 4 }}>
        <Button
          disabled = {isDisabled}
          variant="contained"
          color="primary"
          onClick={handleSubmitOrder}
          sx={{
            borderRadius: '20px',
            backgroundColor: '#581a7d',
            '&:hover': {
              backgroundColor: '#260b36',
            },
          }}
        >
          Submit Order
        </Button>
      </Box>

      <CustomDialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
          navigate('/booking');
        }}
        title={dialogContent.title}
        onSubmit={() => {
          setDialogOpen(false);
          navigate('/booking');
        }}
        submitLabel="Ok"
      >
        <p>{dialogContent.message}</p>
      </CustomDialog>

      <CustomDialog
        open={ratingDialogOpen}
        onClose={() => {
          setRatingDialogOpen(false);
          setShowConfetti(false);
        }}
        title={ratingDialogContent.title}
        onSubmit={() => {
          setRatingDialogOpen(false);
          setShowConfetti(false);
        }}
        submitLabel="Ok"
      >
        <p>{ratingDialogContent.message}</p>
      </CustomDialog>

      <LuckyDrawModal
        amount={luckyDrawAmt}
        open={luckyDrawOpen}
        onClose={() => {
          setLuckyDrawOpen(false);
          setShowConfetti(false);
        }}
      />
    </Box>
  );
}

export default BookingDetails;
