class AboutUsModel {
  constructor({ code = '', data = [], message = '' } = {}) {
    this.code = code;
    this.message = message;
    this.data = Array.isArray(data) 
      ? data.map(aboutUs => new AboutUsDataModel(aboutUs)) 
      : [];
  }
}

class AboutUsDataModel {
  constructor({
    id = '',
    title = '',
    description = '',
    img_path = '',
    sort = 0,
    created_at = '',
    updated_at = '',
    deleted_at = '',
  } = {}) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.imgPath = img_path;
    this.sort = sort;
    this.createdAt = created_at;
    this.updatedAt = updated_at;
    this.deletedAt = deleted_at;
  }
}

export default AboutUsModel;
