class LoginModel {
  constructor({ code = '', accessToken = '', token_type = '', message = '' }) {
    this.code = code;
    this.accessToken = accessToken;
    this.tokenType = token_type;
    this.message = message; 
  }
}

export default LoginModel;
