import React, { useState, useEffect, useRef } from 'react';
import { Box, CircularProgress, Container, Typography, Divider } from '@mui/material';
import { getHelpCenter } from '../../services/repositories/HelpCenterService'; 
import ParallaxHeader from '../parallaxheader/ParallaxHeader'; 
import topBanner from '../../assets/images/topbanner.jpg';
import { CSSTransition } from 'react-transition-group';
import { useNavigate } from 'react-router-dom';
import './HelpCenter.css'; 

function HelpCenter() {
  const [helpCenter, setHelpCenter] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedItemId, setExpandedItemId] = useState(null);
  const descriptionRefs = useRef({});
  const navigate = useNavigate();

  const handleTitleClick = (id) => {
    setExpandedItemId((prevId) => {
      const newId = prevId === id ? null : id;
      
      if (newId !== null && descriptionRefs.current[newId]) {
        setTimeout(() => {
          descriptionRefs.current[newId].scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }, 300); 
      }

      return newId;
    });
  };

  useEffect(() => {
    const fetchHelpCenter = async () => {
      try {
        const data = await getHelpCenter(navigate);
        setHelpCenter(data.data);
      } catch (error) {
        console.error('Error fetching about us:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchHelpCenter();
  }, [navigate]);

  return (
    <Box sx={{ backgroundColor: '#fff' }}> 
      <ParallaxHeader title="FAQ" backgroundImage={topBanner} />
      
      <Container sx={{ mt: 4, padding: { xs: '0', sm: '0 16px', md: '0 32px' } }}>
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center', 
              justifyContent: 'center',
              minHeight: '100vh',
              width: '100%', 
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              backgroundColor: '#ffffff',
              borderRadius: '8px',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
            }}
          >
            {helpCenter.map((item, index) => (
              <React.Fragment key={item.id}>
                {index !== 0 && <Divider sx={{ width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.05)' }} />} 
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    cursor: 'pointer',
                    padding: '16px 0',
                    width: '100%',
                    backgroundColor: 'rgba(137,117,44,0.12)'
                  }}
                  onClick={() => handleTitleClick(item.id)}
                >
                  <Typography
                    variant="h2"
                    sx={{ 
                      textAlign: 'center', 
                      margin: '16px 0', 
                      fontSize: '1.5rem',
                      width: '100%',
                    }} 
                  >
                    {item.title}
                  </Typography>
                </Box>
                
                <CSSTransition
                  in={expandedItemId === item.id}
                  timeout={300}
                  classNames="expand"
                  unmountOnExit
                >
                  <Box
                    ref={(el) => (descriptionRefs.current[item.id] = el)}
                    sx={{ 
                      padding: '16px', 
                      textAlign: 'center', 
                      maxWidth: 'calc(100% - 32px)',
                      width: '100%',
                    }}
                  >
                    {/* <Divider sx={{ mb: 2 }} /> */}
                    <div
                      dangerouslySetInnerHTML={{ __html: item.description }}
                      style={{ 
                        maxHeight: '300px', 
                        overflowY: 'auto', 
                        marginBottom: '16px',
                        padding: '0 16px', 
                      }} 
                    /> 
                  </Box>
                </CSSTransition>
              </React.Fragment>
            ))}
          </Box>
        )}
      </Container>
    </Box>
  );
}

export default HelpCenter;
