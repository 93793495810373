import axios from 'axios';
import { API_ENDPOINTS } from '../../config/ApiConfig';
import GeneralModel from '../models/GeneralModel';
import { handleLogout } from '../../utils/LogoutHelper';

export const postPasswordChange = async ({ old_password, new_password, navigate }) => {
  try {
    const response = await axios.post(API_ENDPOINTS.postPasswordChange, {
      old_password,
      new_password,
    }, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      }
    });

    const data = response.data;

    return new GeneralModel(data);
  } catch (error) {
    if (error.response) {
      const data = error.response.data;
      console.error(`API Error: (${data.status}) ${data.code} - ${data.message}`);
      if (!handleLogout(data.code, navigate)) {
        return new GeneralModel(data);
      }
    } else {
      console.error('Error:', error.message || error);
    }
    throw error;
  }
};
