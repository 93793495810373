import React, { useState } from 'react';
import { Box, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './Footer.css';  
import logo from '../../assets/images/logo2.webp';

const currentYear = new Date().getFullYear();

const Footer = () => {
  const navigate = useNavigate();
  const [isLoginReminderOpen, setIsLoginReminderOpen] = useState(false);

  const handleNavigation = (path) => {
    if (localStorage.getItem('accessToken')) {
      navigate(path);
    } else {
      setIsLoginReminderOpen(true);
    }
  };

  const handleCloseLoginReminder = () => {
    setIsLoginReminderOpen(false);
  };

  return (
    <Box
      sx={{
        backgroundColor: '#f7f7f7',  
        color: '#333',              
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
     
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
       
        <Box sx={{ flexGrow: 1, borderBottom: '1px solid #581a7d', textAlign: 'left', alignContent: 'center', padding: { xs: '50px', ls: '0px' } }}>
          <Box sx={{ display: 'flex', paddingLeft: { lg: '75px' }, flexDirection: { xs: 'column', lg: 'column' } }}>
            <Box sx={{ width: { sx: '100%', lg: '33%' }, paddingBottom: '30px' }}>
              <img src={logo} alt="Logo" />
            </Box>

            <Box sx={{ width: { sx: '100%', md: '33%' } }}>
              <Typography
                variant="body2"
                sx={{
                  textTransform: 'uppercase',
                  borderTop: '1px solid #581a7d',
                  paddingBottom: '30px',
                  paddingTop:'30px',
                  fontFamily: 'Hind Madurai, sans-serif',
                  fontSize: '16px',
                  cursor: 'pointer'
                }}
                onClick={() => handleNavigation('/booking')}
              >
                Booking
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  textTransform: 'uppercase',
                  fontFamily: 'Hind Madurai, sans-serif',
                  fontSize: '16px',
                  paddingBottom: '30px',
                  cursor: 'pointer'
                }}
                onClick={() => handleNavigation('/promo')}
              >
                Events & Offers
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  textTransform: 'uppercase',
                  fontFamily: 'Hind Madurai, sans-serif',
                  fontSize: '16px',
                  paddingBottom: '30px',
                  cursor: 'pointer'
                }}
                onClick={() => handleNavigation('/customer-service')}
              >
                Contact Us
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  textTransform: 'uppercase',
                  fontFamily: 'Hind Madurai, sans-serif',
                  fontSize: '16px',
                  paddingBottom: '30px',
                  cursor: 'pointer'
                }}
                onClick={() => handleNavigation('/about-us')}
              >
                Help Center
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ paddingTop: '10px', paddingBottom: '40px', paddingLeft: '90px' }}>
          <Typography variant="body2"
            sx={{
              fontFamily: 'Hind Madurai, sans-serif',
              fontSize: '16px',
              textAlign: 'left',
            }}
          >
            {currentYear} © Kapish Jewels | All Rights Reserved 
          </Typography>
        </Box>
      </Box>
      
      <Dialog
        open={isLoginReminderOpen}
        onClose={handleCloseLoginReminder}
        aria-labelledby="login-reminder-dialog-title"
        aria-describedby="login-reminder-dialog-description"
      >
        <DialogTitle id="login-reminder-dialog-title">{"Please Log In"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="login-reminder-dialog-description">
            You need to be logged in to continue. Please log in to access this feature.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLoginReminder} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Footer;
