import React, { useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import Slider from "react-slick";
import PopUpDialog from '../../components/popupdialog/PopUpDialog'; 
import { useNavigate } from 'react-router-dom'; 

import img1 from '../../assets/images/topbanners/1.jpg';
import img2 from '../../assets/images/topbanners/2.jpg';
import img3 from '../../assets/images/topbanners/3.jpg';
import img4 from '../../assets/images/topbanners/4.jpg';
import img5 from '../../assets/images/topbanners/5.jpg';
import img6 from '../../assets/images/topbanners/6.jpg';

const images = [img1, img2, img3, img4, img5, img6];

const Topbanner = () => {
  const navigate = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoginReminderOpen, setIsLoginReminderOpen] = useState(false);

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleCloseLoginReminder = () => {
    setIsLoginReminderOpen(false);
  };

  const onImageClick = () => {
    if (localStorage.getItem('accessToken')) {
      navigate('/booking');
    } else {
      setIsLoginReminderOpen(true);
    }
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%', 
        overflow: 'hidden',
        position: 'relative',
        paddingTop: 8,   
        margin: 0,    
      }}
    >
      <Slider {...sliderSettings} sx={{ padding: 0, margin: 0 }}>
        {images.map((image, index) => (
          <Box
            key={index}
            component="img"
            src={image}
            alt={`Banner ${index + 1}`}
            onClick={onImageClick}
            sx={{
              width: '100%',
              height: '100%',  
              objectFit: 'cover',
              cursor: 'pointer',
              padding: 0,  
              margin: 0,   
            }}
          />
        ))}
      </Slider>

      <PopUpDialog open={isDialogOpen} onClose={handleCloseDialog} />

      <Dialog
        open={isLoginReminderOpen}
        onClose={handleCloseLoginReminder}
        aria-labelledby="login-reminder-dialog-title"
        aria-describedby="login-reminder-dialog-description"
      >
        <DialogTitle id="login-reminder-dialog-title">{"Please Log In"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="login-reminder-dialog-description">
            You need to be logged in to continue. Please log in to access this feature.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLoginReminder} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Topbanner;
