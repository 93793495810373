class ProductListModel {
    constructor({ code = '', data = [], message = '' } = {}) {
      this.code = code;
      this.message = message;
      this.data = Array.isArray(data) 
        ? data.map(product => new ProductDataModel(product)) 
        : [];
    }
  }
  
  class ProductDataModel {
    constructor({
      id = '',
      title = '',
      description = '',
      img_path = '',
      created_at = '',
      updated_at = '',
      deleted_at = '',
    } = {}) {
      this.id = id;
      this.title = title;
      this.description = description;
      this.imgPath = img_path;
      this.createdAt = created_at;
      this.updatedAt = updated_at;
      this.deletedAt = deleted_at;
    }
  }
  
  export default ProductListModel;
  