import React, { useState, useEffect } from 'react';
import { Box, Container, Grid, Typography, Divider, CircularProgress } from '@mui/material';
import { getProfile } from '../../services/repositories/ProfileService';
import ParallaxHeader from '../parallaxheader/ParallaxHeader';
import topBanner from '../../assets/images/topbanner.jpg';

function SignInRewards() {
  const [signInNumber, setSignInNumber] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const data = await getProfile();
        setSignInNumber(data.data.customer.signInNumber);
        // setSignInNumber(3);
      } catch (error) {
        console.error('Error fetching profile:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  const gridItemStyle = {
    width: '100px', 
    height: '100px', 
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid #ddd',
    borderRadius: '8px',
    fontWeight: 'bold',
    fontSize: '24px',
    color: '#ddd',
    transition: 'background-color 0.3s, color 0.3s',
    textAlign: 'center',
  };

  const highlightedStyle = {
    backgroundColor: '#581a7d',
    // color: '#581a7d',
    color: 'white'
  };

  const renderGrid = (index) => (
    <Box
      key={index}
      sx={{ 
        ...gridItemStyle,
        ...(index < signInNumber ? highlightedStyle : {})
      }}
    >
      Day<br/>{index + 1}
    </Box>
  );

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          width: '100%',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (signInNumber === null) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Typography variant="h6">No Data Available</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', backgroundColor: '#fff', minHeight: '100vh' }}>
      <ParallaxHeader title="Sign-in Rewards" backgroundImage={topBanner} />
      <Box sx={{ flexGrow: 1, padding: { xs: '16px', sm: '32px' }, mt: 10 }}>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {[0, 1, 2, 3].map((_, index) => (
                  <Grid item xs={6} sm={3} md={3} key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
                    {renderGrid(index)}
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {[4, 5, 6].map((_, index) => (
                  <Grid item xs={6} sm={4} md={4} key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
                    {renderGrid(index + 4)}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Divider sx={{ my: 4, width: '100%' }} />
      <Box sx={{ padding: '16px', display: 'flex', justifyContent: 'center', marginBottom: '50px' }}>
        <Typography variant="h6">
          Sign in consecutively to earn more rewards!
        </Typography>
      </Box>
    </Box>
  );
}

export default SignInRewards;
