import React, { useState, useEffect } from 'react';
import { Box, Container, Grid, Typography, Divider, CircularProgress, TextField, Button, Paper } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import ParallaxHeader from '../parallaxheader/ParallaxHeader'; 
import topBanner from '../../assets/images/topbanner.jpg';
import CustomDialog from '../customdialog/CustomDialog';
import { getProfile } from '../../services/repositories/ProfileService';
import { postWithdrawalCreate } from '../../services/repositories/WithdrawalCreateService';

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#581a7d',
    },
    '&:hover fieldset': {
      borderColor: '#581a7d',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#581a7d',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#581a7d',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#581a7d',
  },
  '& .MuiInputBase-input': {
    fontFamily: 'Hind Madurai',
    color: '#581a7d',
    textAlign: 'right',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '20px',
  border: `1px solid #581a7d`,
  color: '#581a7d',
  fontFamily: 'Hind Madurai, sans-serif',
  padding: theme.spacing(1, 2),
  margin: theme.spacing(0.5),
  '&:hover': {
    backgroundColor: 'rgba(82, 7, 12, 0.1)',
  },
}));

const WithdrawalHistoryButton = styled(Button)(() => ({
  textTransform: 'none',
  color: '#581a7d',
  fontFamily: 'Hind Madurai, sans-serif',
  padding: 0,
  border: 'none',
  background: 'none',
  textDecoration: 'underline',
  '&:hover': {
    background: 'none',
    textDecoration: 'underline',
  },
}));

function Withdrawal() {
  const [amount, setAmount] = useState('');
  const [pin, setPin] = useState('');
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isFailedDialogOpen, setIsFailedDialogOpen] = useState(false);
  const [errorTitle, setErrorTitle] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [dialogContent, setDialogContent] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false); 
  const [isDisabled, setIsDisabled] = useState(false);
  const [isRan, setIsRan] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const data = await getProfile(navigate);
        setProfile(data.data);
      } catch (error) {
        console.error('Error fetching profile:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [navigate]);

  const handleAmountChange = (event) => {
    const value = event.target.value;
    const regex = /^\d*\.?\d{0,2}$/;
    if (regex.test(value)) {
      setAmount(value);
    }
  };

  const handleAllClick = () => {
    if (profile && profile.customer.availableWithdrawAmt) {
      setAmount(profile.customer.availableWithdrawAmt.toString());
    }
  };

  const handlePinChange = (event) => {
    const value = event.target.value;
    const regex = /^\d{0,6}$/;
    if (regex.test(value)) {
      setPin(value);
    }
  };

  const handleFailedDialogClose = () => {
    setIsFailedDialogOpen(false);
  };

  const handleContinue = async () => {
    if (!amount || parseFloat(amount) <= 0) {
      setDialogContent('Amount must be more than 0.');
      setIsSuccess(false);
      setIsDialogOpen(true);
      return;
    }

    if (parseFloat(amount) > profile.customer.availableWithdrawAmt) {
      setDialogContent('Amount exceeds available withdrawal limit.');
      setIsSuccess(false);
      setIsDialogOpen(true);
      return;
    }

    if (!pin) {
      setDialogContent('PIN cannot be empty.');
      setIsSuccess(false);
      setIsDialogOpen(true);
      return;
    }

    setIsDisabled(true);

    try {
      if(profile.customer.disabledWithdrawal !== 1 && !isRan){
        setSubmitLoading(true); 
        const response = await postWithdrawalCreate({ amount, pin });
        setSubmitLoading(false); 
        if (response.code === '2000' || response.code === 2000) {
          setIsRan(true);
          setDialogContent('Withdrawal successful.');
          setIsSuccess(true);
          setIsDialogOpen(true);
  
          const timeoutId = setTimeout(() => {
            setDialogContent(false);
            navigate('/booking');
          }, 2000);
  
          return () => clearTimeout(timeoutId);
        } else {
          setDialogContent(response.message || 'Withdrawal failed.');
          setIsSuccess(false);
          setIsDialogOpen(true);
          setIsDisabled(false);
        }
      } else {
        setErrorTitle(profile.customer.disabledWithdrawalTitle);
        setErrorMessage(profile.customer.disabledWithdrawalDescription);
        setIsFailedDialogOpen(true);
        setIsDisabled(false);
      }
    } catch (error) {
      setDialogContent('An error occurred. Please try again.');
      setIsSuccess(false);
      setIsDialogOpen(true);
      setIsDisabled(false);
    } 
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleHistoryClick = () => {
    navigate('/withdrawal-list');
  };

  const handleAddBankClick = () => {
    navigate('/add-bank');
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          width: '100%', 
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!profile) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh', 
        }}
      >
        <Typography variant="h6">No Data Available</Typography>
      </Box>
    );
  }

  //const isBankInfoEmpty = !profile.customer.bankOwnerName && !profile.customer.bankOwnerCardNo && !profile.customer.bankName;

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <ParallaxHeader title="Withdrawal" backgroundImage={topBanner} />
      
      <Container sx={{ flex: 1, mt: 4, position: 'relative' }}>
        <WithdrawalHistoryButton onClick={handleHistoryClick}>
          Withdrawal History
        </WithdrawalHistoryButton>
        <Typography variant="caption" color="textSecondary" align="center" sx={{ display: 'block', mb: 1 }}>
          Withdrawals will be received usually within an hour.
        </Typography>
        <Typography variant="body1" sx={{ mb: 4, fontWeight: 'bold', fontSize: '30px', color: '#581a7d' }} align="center">
          ₹{profile.customer.availableWithdrawAmt}
        </Typography>
        <Typography variant="h6" align="center" sx={{ mb: 2 }}>
          Withdraw to bank card
        </Typography>
        <Grid container spacing={3} sx={{ mb: 4 }}>
          <Grid item xs={12} sm={4}>
            <Paper elevation={3} sx={{ padding: '16px', backgroundColor: '#fff', borderRadius: '30px' }}>
              <Typography variant="body1"><strong>Name:</strong> {profile.customer.bankOwnerName ?? '-'}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper elevation={3} sx={{ padding: '16px', backgroundColor: '#fff', borderRadius: '30px' }}>
              <Typography variant="body1"><strong>Bank Account Number/UPI ID:</strong> {profile.customer.bankOwnerCardNo ?? '-'}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper elevation={3} sx={{ padding: '16px', backgroundColor: '#fff', borderRadius: '30px' }}>
              <Typography variant="body1"><strong>Bank Name/UPI:</strong> {profile.customer.bankName ?? '-'}</Typography>
            </Paper>
          </Grid>
        </Grid>

        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
          <StyledButton onClick={handleAddBankClick}>
            Add/Edit Bank
          </StyledButton>
        </Box>

        <Divider sx={{ my: 4 }} />

        <Box display="flex" alignItems="center" sx={{ mb: 4 }}>
          <StyledTextField
            label="Amount"
            value={amount}
            fullWidth
            variant="outlined"
            onChange={handleAmountChange}
            placeholder="0.00"
            sx={{ mr: 2 }} 
          />
          <StyledButton onClick={handleAllClick}>
            All
          </StyledButton>
        </Box>
        <Box display="flex" alignItems="center">
          <StyledTextField
            label="Withdrawal Pin"
            value={pin}
            fullWidth
            variant="outlined"
            onChange={handlePinChange}
            placeholder="000000"
            inputProps={{ maxLength: 6 }}
            sx={{ mt: 2, paddingRight: '88px' }}
          />
        </Box>
      </Container>

      <Divider sx={{ my: 4 }} />

      <Box sx={{ padding: '16px', display: 'flex', justifyContent: 'center', mb: 4 }}>
        <Button
          disabled={isDisabled}
          variant="contained"
          color="primary"
          sx={{ borderRadius: '20px', backgroundColor: '#581a7d', color: '#fff' }}
          onClick={handleContinue}
        >
          WITHDRAWAL
        </Button>
      </Box>

      <CustomDialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        title={isSuccess ? 'Success' : 'Failed'}
        onSubmit={() => isSuccess ? navigate('/booking'):setIsDialogOpen(false)}
        submitLabel={isSuccess ? 'OK' : 'Retry'}
        submitLoading={submitLoading}
        submitDisabled={submitLoading}
      >
        {dialogContent}
      </CustomDialog>

      <CustomDialog
        open={isFailedDialogOpen}
        onClose={handleFailedDialogClose}
        title={errorTitle ?? "Note"}
        onSubmit={handleFailedDialogClose}
        submitLabel="Ok"
      >
        <p>{errorMessage}</p>
      </CustomDialog>
    </Box>
  );
}

export default Withdrawal;
